import React from "react";

// Customizable Area Start
import "./CatalogueStyles.css";
import CustomLoader from "../../../components/src/CustomLoader.web";
import { Accordion, AccordionSummary, Button, FormControlLabel, IconButton, Radio, RadioGroup } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded'
import CustomRadioBox from "../../../components/src/CustomRadioBox.web";
import { SidesTypes, SpiceLevelTypes } from "../../ordermanagement/src/types";
import CustomGreenCheckbox from "../../../components/src/CustomGreenCheckBox.web";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import SearchIcon from '@material-ui/icons/Search';
import { InputAdornment, TextField } from "@mui/material";
import ClearRoundedIcon from '@material-ui/icons/ClearRounded'
// Customizable Area End

import CfposPrductDescriptionController, {
  DealProduct,
  Props
} from "./CfposPrductDescriptionController";
import {CustomizationData } from "./CfposordermanagementfeController";
import CustomPosRadioBox from "../../../components/src/CustomPosRadioBox";

export default class CfposPrductDescription extends CfposPrductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  accordianContainer = (spiceLevelList: SpiceLevelTypes[]) => {
    return (
      <Accordion
        expanded={this.state.expanded == `spiceLevel`}
        onChange={this.handleExpand(`spiceLevel`)}
        className="accordianPosProductDescription"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="accordian_spicelevel_summar"
          id="accordianPosProductDescriptionSummary"
        >
          Choose your spice level...
        </AccordionSummary>
        <RadioGroup
          row
          style={{ display: 'flex', flexDirection: 'row', gap: '16px', paddingTop: "12px" }}
          defaultValue={this.state.spiceLevel}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={this.setSpiceLevel}
        >
          {spiceLevelList.map((value: SpiceLevelTypes) =>
            <FormControlLabel key={value.id}
              value={value.attributes.name}

              control={<Radio style={{ display: "none" }} />}
              label={<div className={this.isSelectedSpiceLevel(value.attributes.name) ? "spiceLevelLabelValuePDSelected" : "spiceLevelLabelValuePD"}><div className="spiceImageContainer" style={{ backgroundImage: `url(${value.attributes.images.url})` }} ></div>
                <div>{value.attributes.name}</div>
              </div>} />

          )}
        </RadioGroup>
      </Accordion>
    )
  }


  accordianChooseYourType = (typeData: { two_reg_sides_price: number | null, on_its_own_price: number | null }) => {
    const { sidesDetails, chooseYourType } = this.state
    return (
      <Accordion
          expanded={this.state.expanded == `chooseYourTypeAccordian`}
          onChange={this.handleExpand(`chooseYourTypeAccordian`)}
        className="accordianPosProductDescription">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="accordian_spicelevel_summar"
          id="accordianPosProductDescriptionSummary"
        >
          Choose your type...
        </AccordionSummary>

        <RadioGroup
          id="changeChooseYourType"
          row
          value={chooseYourType}
          style={{ gap: "32px", paddingTop: "12px" }}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={this.changeChooseYourType} 
         
        >
          <FormControlLabel className='form_control_label_choose_yourType'
            name={`${typeData.on_its_own_price}`}
            value={'on its own'}
            control={<CustomRadioBox/>}
            label={<div className='spiceLevel_label'><div><div style={{ fontSize: "18px", color: "#1D1D1D", fontWeight: 700 }} >On its own</div>
              <div style={{ fontSize: "14px", color: "#747474", fontWeight: 700 }} >£{typeData.on_its_own_price}</div></div>
            </div>} />
          <FormControlLabel className='form_control_label_choose_yourType'
            name={`${typeData.two_reg_sides_price}`}
            value={'with 2 reg sides'}
            control={<CustomRadioBox/>}
            label={<div className='spiceLevel_label'><div><div style={{ fontSize: "18px", color: "#1D1D1D", fontWeight: 700 }} >With 2 reg sides</div>
              <div style={{ fontSize: "14px", color: "#747474", fontWeight: 700 }} >£{typeData.two_reg_sides_price}</div></div>
            </div>} />
        </RadioGroup>

        {chooseYourType === 'with 2 reg sides' && sidesDetails.length > 0 &&

          this.sidesDetailsContainer(sidesDetails)
        }
      </Accordion>
    )
  }

  sidesDetailsContainer = (sidesDetails: SidesTypes[]) => {
    return (
      <div className="sideContainerRegSide">
        <div className="categoryHeaderContainer" id="categoryHeaderContainer0"> Sides </div>
        <RadioGroup
          data-testId="sides"
          className="categoryItemContainer"
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >

          {sidesDetails.map((value: SidesTypes) =>
            <FormControlLabel key={value.id} className='form_control_label_spicelevel'
              data-testId="spiceLevel"
              value={value.id}
              onChange={(e) => { this.handleSideChange(value.id) }}
              control={
                <div>
                  <CustomGreenCheckbox style={{ position: "absolute", zIndex: 2, marginLeft: "3px" }}
                    checked={this.state.selectedSides.includes(value.id)}
                    disabled={!this.state.selectedSides.includes(value.id) && this.state.selectedSides?.length >= 2}
                  />
                  {this.itemContainer(value)}
                </div>
              }
              label={""} />
          )}

        </RadioGroup>
      </div>
    )
  }

  catalogueHeaderMenuDealsSearchBar = () => {
    return (
      <TextField
        fullWidth
        id="outlined-basic"
        variant="outlined"
        placeholder="Search for an item here"
        className="searchField"
        style={{ height: '44px', maxWidth:"362px"}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: "#A2A2A2" }} />
            </InputAdornment>
          ),
          endAdornment:(
            this.state.searchToppings &&
            <InputAdornment 
            onClick={this.handleSearchClear} 
            style={{cursor:"pointer"}} position="end">
              <ClearRoundedIcon style={{color:"#A2A2A2"}} />
            </InputAdornment>
          ),
          className: 'posToppingsSearchInput'
        }}
        onChange={this.onChangeToppingsSearch}
        value={this.state.searchToppings}
      />
    )
  }

  itemContainer = (data: SidesTypes,withPrice?:boolean) => {
    const showPrice = withPrice !== undefined ? withPrice : true; 
    return (
      <div className="itemInfoContainer productDescription" tabIndex={0}>
        <div className="itemImageContainer" style={{ backgroundImage: `url(${data.attributes.images.url})` }} ></div>
        <div className="itemInfo">
          <div className="itemInfoTitle">{data.attributes.sub_sub_category_name}
          </div>{showPrice &&
          <div className="itemInfoDescriptionPrice">£{data.attributes.on_its_own_price}</div>}
        </div>
      </div>
    )
  }

  productDescriptioHeader = (values: { title: string, on_its_own_price: number, reg_sides_price: number | null, hasMains: boolean, hasDeals: boolean }) => {
    return (
      <div className="productDescriptionHeader" >
        <div className="productDescriptionTitleContainer" >
          <div className="productDescriptionTitle">{values.title}</div>
          {!values.hasDeals && <div className="productDescriptionTitle">{values.hasMains && "From"}(£{this.state.chooseYourType === "on its own" ? this.checkFinalPrice(values.on_its_own_price) : this.checkFinalPrice(values.reg_sides_price ||0)})</div>}
        </div>
        <div className="splitContainer">
          {this.getSplitItems.map((value: string, index: number) =>
            <div className="splitContainerItems" key={`${value}${index}`}>{value}</div>
          )}
        </div>

      </div>
    )
  }

  notesAccordian = () => {
    const { productChefNotes, productChefNotesWordCount, productChefNotesWordCountError } = this.state
    return (
      <Accordion
        expanded={this.state.expanded == `notesPanel`}
        onChange={this.handleExpand(`notesPanel`)}
        className="accordianPosProductDescription"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="accordian_spicelevel_summar"
          id="accordianPosProductDescriptionSummary"
        >
          Add Notes...
        </AccordionSummary>
        <div className='exclude_allergen_container' style={{ paddingTop: "12px" }} >
          <textarea
            style={{ borderColor: productChefNotesWordCountError ? "#E11B22" : "#BFBFBF" }}
            className='notesAccordainTextArea'
            placeholder='Add notes to the chef'
            onChange={this.handleChangeProductChefNotes}
            value={productChefNotes}
          />
          <div className='wordCount_excludeAllergen_container'>
            <div className='wordCount_excludeAllergen'>{productChefNotesWordCount}/50</div>
            <div className='wordCount_excludeAllergen_error'>{productChefNotesWordCountError}</div>
          </div>

        </div>
      </Accordion>
    )
  }

  dealableProducts = (deal_products: DealProduct[]) => {
    const validProducts = deal_products.filter(
      ({ attributes: { dealable_type } }) =>
        dealable_type !== "BxBlockCatalogue::FoodCategory" &&
        dealable_type !== "BxBlockCatalogue::SubSubCategory"
    );

    return validProducts.flatMap(({ attributes: { quantity, dealable: { id, title } } }) => {
      const itemsArray = Array.from({ length: quantity });
      return itemsArray.map((_, idx) => (
        <Accordion
        expanded={this.state.expanded == `list${id}${idx}Details`}
        onChange={this.handleExpand(`list${id}${idx}Details`)}
          key={`${id}-${idx}`}
          className="accordianPosProductDescription"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="accordian_spicelevel_summar"
            id="accordianPosProductDescriptionSummary"
          >
            Choose {title.toLowerCase()}...
          </AccordionSummary>
          <RadioGroup
            row
            className="categoryItemContainer"
            defaultValue=""
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            style={{paddingTop: "12px" }}      
            value={this.showVal(id, idx)}    
            onChange={(e) => {
              this.setDynamicState(e.target.value, id, idx,this.state[`list${id}Details`] && this.createArrayTocheckExtra(this.state[`list${id}Details`]))
             }}  
          >
            {this.state[`list${id}Details`] && this.state[`list${id}Details`].map((value: any) => (
              <FormControlLabel
                key={value.id}
                className="form_control_label_spicelevel"
                data-testId="spiceLevel"
                value={value.id}
                control={
                  <div>
                    <CustomPosRadioBox
                style={{ position: "absolute", zIndex: 2, marginLeft: "3px" }}
                value={value.id}
                    />
                    {this.itemContainer(value)}
                  </div>
                }
                label=""
              />
            ))}
          </RadioGroup>
        </Accordion>
      ));
    });
  };


  

  itemCustomizationAccordian = (customisations: CustomizationData[]) => {
    return customisations.map((customisation, idx) => {
      const customizationId = customisation.id;
      const itemCustomizations = this.state[`list${customizationId}ItemCustomisation`] || [];
      const arrayTocheck = itemCustomizations.map((item:any)=> (Number(item.id)))

      if (!itemCustomizations.length) return null;

      return (
        <Accordion
          key={`${customizationId}-${idx}`}
          className="accordianPosProductDescription"
          expanded={this.state.expanded == `list${customizationId}ItemCustomisation`}
          onChange={this.handleExpand(`list${customizationId}ItemCustomisation`)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="accordian_spicelevel_summary"
            id="accordianPosProductDescriptionSummary"
          >
            {customisation.title}{customisation.mandatory && "*"}...
          </AccordionSummary>
          <RadioGroup
            row
            className="categoryItemContainer"
            defaultValue=""
            value={this.showVal(customizationId, idx)}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            style={{paddingTop: "12px" }}
          >
            {itemCustomizations.map((value: any) => (
              <FormControlLabel
                key={value.id}
                className="form_control_label_spicelevel"
                data-testId="spiceLevel"
                value={value.id}
                control={
                  <div>
                    {this.state[`selectMultiple${customizationId}`]? 
                    <CustomGreenCheckbox
                   style={{ position: "absolute", zIndex: 2, marginLeft: "3px" }}
                      onChange={()=>{this.setItemsOnPriceBasis(Number(value.id),this.state[`priceOption${customizationId}`],value.attributes.on_its_own_price,customizationId,customisation.mandatory)}}
                      checked={this.findCheckedStatus(Number(value.id))}
                    />
                    :<CustomPosRadioBox
                    style={{ position: "absolute", zIndex: 2, marginLeft: "3px" }}
                      onChange={()=>{this.setItemsOnPriceBasisSingle(Number(value.id),this.state[`priceOption${customizationId}`],value.attributes.on_its_own_price,customizationId,customisation.mandatory,arrayTocheck)}}
                      checked={this.findCheckedStatus(Number(value.id))}
                    />}
                    {this.itemContainer(value,this.state[`priceOption${customizationId}`])}
                  </div>
                }
                label=""
              />
            ))}
          </RadioGroup>
        </Accordion>
      );
    });
  }


  toppingCustomizationsDeals = (deal_products: DealProduct[]) => {
    const {filterSelectedDietToppings,dietType} = this.state
    const validProducts = deal_products.filter(
      ({ attributes: { dealable_type } }) =>
        dealable_type !== "BxBlockCatalogue::FoodCategory" &&
        dealable_type !== "BxBlockCatalogue::SubSubCategory"
    );

    return validProducts.flatMap(({ attributes: { quantity, dealable: { id, title } } }) => {
      const itemsArray = Array.from({ length: quantity });
      return itemsArray.map((_, idx) => (
        <Accordion
        expanded={this.state.expanded == `list${id}${idx}Details`}
        onChange={this.handleExpand(`list${id}${idx}Details`)}
          key={`${id}-${idx}`}
          className="accordianPosProductDescription"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="accordian_spicelevel_summar"
            id="accordianPosProductDescriptionSummary"
          >
            {title}...
          </AccordionSummary> 
          <div className="toppingsFilterSearchContainer" >
          <div style={{display:"flex", flexDirection:"row", gap:"16px", flexWrap:'wrap'}}>
                      {dietType.map((item)=>(
                          <label style={{cursor: 'pointer', display: 'inline-flex', alignItems: 'center', padding:"8px 16px", borderRadius:"26px",
                                color: filterSelectedDietToppings === item.value ? '#fff' : '#525252',background: filterSelectedDietToppings === item.value ? 'linear-gradient(99.09deg, #F6B8BB 2.64%, #E11B23 100%)' : '#F1F1F1'}} key={item.id}>
                              <input onChange={this.handleDietChangeToppings} checked={filterSelectedDietToppings===item.value} type='radio' name={item.title} value={item.value} style={{display: 'none'}} />
                              {item.title}
                          </label>
                      ))}
                  </div>
                  {this.catalogueHeaderMenuDealsSearchBar()}
          </div>

          <RadioGroup
            row
            className="categoryItemContainerToppings"
            defaultValue=""
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            style={{paddingTop: "12px" }}      
            value={this.showVal(id, idx)}    
            
          >
            {this.state[`list${id}Details`] && this.filteredData(this.state[`list${id}Details`]).length>0 ?
            (this.filteredData(this.state[`list${id}Details`]).map((value: any) => (
              <FormControlLabel
                key={value.id}
                className="form_control_label_spicelevel_for_toppings"
                data-testId="spiceLevel"
                value={value.id}
                control={
                  <div style={{display:"flex"}}>
                    <CustomGreenCheckbox
                    onChange={(e) => {
                      this.setDynamicStateToppings(value.id, id)
                     }}  
                     checked={this.findCheckedStatusToppingsFree(Number(value.id))}
                value={value.id}
                disabled={
                  this.isAllItemsSelected(this.createArrayTocheckExtra(this.state[`list${id}Details`])) &&
                  !this.state.without_price_item.includes(Number(value.id))
                }
                    />
                  </div>
                }
                label={value.attributes.sub_sub_category_name}
              />
            ))):
            (
              <div style={{ padding: "10px", fontSize: "14px", color: "#747474" }}>
                No results found
              </div>
            )}
          </RadioGroup>

          <div id="accordianPosProductDescriptionSummary" style={{fontSize:"16px",paddingTop:"20px"}}>Add extra {title} </div>

          <RadioGroup
            row
            className="categoryItemContainerToppings"
            defaultValue=""
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            style={{paddingTop: "12px" }}     
          >
            {this.state[`list${id}Details`] && this.filteredData(this.state[`list${id}Details`]).length>0 ?
            (this.filteredData(this.state[`list${id}Details`]).map((value: any) => (
              <FormControlLabel
                key={value.id}
                className="form_control_label_spicelevel_for_toppings"
                data-testId="spiceLevel"
                value={value.id}
                control={
                  <div style={{display:"flex"}}>
                    <CustomGreenCheckbox
                    onChange={()=>{ this.setDynamicStateExtra(value.id,value.attributes.on_its_own_price ,id)}}
                    checked={this.findCheckedStatusToppings(Number(value.id))}
                    value={value.id}
                    />
                  </div>
                }
                label={<div style={{display:"flex", flexDirection:"row"}}>{value.attributes.sub_sub_category_name} (£{value.attributes.on_its_own_price})</div>}
              />
            ))):
            (
              <div style={{ padding: "10px", fontSize: "14px", color: "#747474" }}>
                No results found
              </div>
            )}
          </RadioGroup>
        </Accordion>
      ));
    });
  }

  getButtonContainer=()=>{
    return(
        <div className='button_container'>
          <div style={{display:"flex",gap:"12px",alignItems:"center"}}>
            <IconButton disabled={this.state.product_quantity<2} onClick={this.minusTheProductQuantity} className='product_minus_add_button'><RemoveRoundedIcon style={{color:"#286532"}}/></IconButton>
          <div style={{fontWeight:700}}>{String(this.state.product_quantity).padStart(2, '0')}</div>
          <IconButton onClick={this.addTheProductQuantity} className='product_minus_add_button'><AddRoundedIcon style={{color:"#286532"}}/></IconButton></div>
          <Button disabled={this.buttonDisabled()} onClick={this.addItemsToCart} style={{ height: '56px', color: this.buttonDisabled()?"#909090":"#fff", backgroundColor: this.buttonDisabled()?"#BFBFBF":"#E11B22", width:"400px", padding:"16px", boxSizing:"border-box"}}>ADD TO CART</Button>
        </div>
    )
  }
  accordianChooseNibblesLighterBites = (validProducts: DealProduct[], idx: number) => {
    const { expanded } = this.state;
    const selectedBite = this.state[`chooseLigtherBites${idx}`];
    const selectedList = this.state[`list${selectedBite}Details`] || [];
  
    const handleSelectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setNibblesLightBite(e.target.value, idx);
    };
  
    const handleDetailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setNibblesAndLightBite(
        e.target.value,
        Number(selectedBite),
        idx,
        this.createArrayTocheckExtra(selectedList)
      )
    };
  
    return (
      <Accordion
        className="accordianPosProductDescription"
        expanded={expanded === `panel3${idx}`}
        onChange={this.handleExpand(`panel3${idx}`)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="accordian_spicelevel_summary"
          id="accordianPosProductDescriptionSummary"
        >
          Choose either Lighter Bite or Nibbles...
        </AccordionSummary>
  
        {/* light bite or nibble slection */}
        <RadioGroup
          row
          className="categoryItemContainer"
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          style={{ paddingTop: "12px" }}
          onChange={handleSelectionChange}
        >
          {validProducts.map(({ attributes: { dealable } }) => (
            <FormControlLabel
              key={dealable.id}
              className="form_control_label_spicelevel"
              style={{ borderBottom: "0px" }}
              value={`${dealable.id}`}
              control={<CustomRadioBox checked={selectedBite === `${dealable.id}`} />}
              label={
                <div className="spiceLevel_label" style={{ fontSize: "18px", fontWeight: 700, color: "#1D1D1D" }}>
                  {dealable.title}
                </div>
              }
            />
          ))}
        </RadioGroup>
  
        {/* item selected*/}
        {selectedList.length > 0 && (
          <RadioGroup
            row
            className="categoryItemContainer"
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            style={{ paddingTop: "12px" }}
            value={this.showVal(Number(selectedBite), idx)}
            onChange={handleDetailChange}
          >
            {selectedList.map((value: any) => (
              <FormControlLabel
                key={value.id}
                className="form_control_label_spicelevel"
                data-testId="spiceLevel"
                value={value.id}
                control={
                  <div>
                    <CustomPosRadioBox  style={{ position: "absolute", zIndex: 2, marginLeft: "3px" }}value={value.id} />
                    {this.itemContainer(value)}
                  </div>
                }
                label=""
              />
            ))}
          </RadioGroup>
        )}
      </Accordion>
    );
  };
  
  
  // Customizable Area End

  render() {
    const { catalogueItemData, spiceLevelList } = this.props;

    if (!catalogueItemData) {
      return <CustomLoader loading={true} />;
    }

    const {
      attributes: {
        sub_sub_category,
        on_its_own_price,
        two_reg_sides_price,
        food_category,
        deal_products,
        item_customization,
      },
    } = catalogueItemData;

    const hasDeals: boolean = food_category === "Deals";
    const hasMains: boolean = food_category === "Mains";
    const hasDealProducts:boolean = !!deal_products && deal_products.length>0;
    const hasItemCustomization = item_customization?.data?.attributes?.customizations?.length > 0;
    const validProducts = !!deal_products &&  deal_products.filter(
      ({ attributes: { dealable_type } }) =>
        dealable_type === "BxBlockCatalogue::FoodCategory" 
    );

    const {toppings,otherProducts} = this.getToppingsDataSeparated(deal_products||[])
    return (
      <div className="productDescriptionContainer">
        {/* Product Description Header */}
        {this.productDescriptioHeader({
          title: sub_sub_category.title,
          on_its_own_price,
          reg_sides_price: two_reg_sides_price,
          hasMains, hasDeals
        })}

        {/* Deals Header */}
        {hasDeals && this.getDealsTitle(catalogueItemData.attributes) && (
          <div data-testid="sides-header" className="sides_description_header">
            <span className="item_information_subsubcategory">
              {this.getDealsTitle(catalogueItemData.attributes)} Included
            </span>
          </div>
        )}

        {/* Spice Level Accordian */}
        {(hasMains || hasDeals) && this.accordianContainer(spiceLevelList)}

        {/* LighterBitesNibbles Options */}

        {(validProducts && validProducts.length )?
        [...Array(validProducts[0].attributes.quantity)].map((_, index) => (
            this.accordianChooseNibblesLighterBites(validProducts,index)
        )):""
    }

    {toppings.length > 0? this.toppingCustomizationsDeals(toppings): ""
    }

        {/* Dealable Products or Choose Your Type */}
        {hasDealProducts
          ? this.dealableProducts(otherProducts)
          : two_reg_sides_price &&
          this.accordianChooseYourType({
            two_reg_sides_price,
            on_its_own_price,
          })}

        {/* Item Customization Accordian */}
        {hasItemCustomization &&
          this.itemCustomizationAccordian(
            item_customization.data.attributes.customizations
          )}

        {/* Notes Accordian */}
        {this.notesAccordian()}
        
        {/* Button Container*/}
        {this.getButtonContainer()}
      </div>
    );
  }
  
}

