import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface UserData{
  first_name: string;
  last_name: string;
  email:string |null;
  full_phone_number: string |null;
  country_code: string|null;
  phone_number: string |null;
  gender: number |null | string;
  date_of_birth: string |null;
  errors: any|null;

}
export interface ResponseOnly {
  errors: ErrorsObject;
  message: object;
}
export interface ErrorsObject {
otp: string;
}
export interface MobileData{
  attributes: MobileAttribute;
}
export interface MetaData{
  token: string;
}
export interface MobileAttribute{
  full_phone_number: string;
  pin: number;
}

export interface ResponseData{
data:MobileData;
meta:MetaData;
errors: any;
message:string;
}
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  email: string |null;
  phoneNumber: string |null;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string |null;
  user_name: string;
  dateOfBirth: string |null;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  editPersonalDetails: boolean;
  editFirstName: string;
  editLastName:string;
  editEmail:string |null;
  editPhone: string |null;
  editDOB: string |null;
  editGender: string |null;
  logoutPopup: boolean;
  emailError: string | null;
  emailPopup: boolean;
  updatePopup: boolean;
  updatedEmail:string;
  successOpen:boolean;
  valueUpdated:string;
  updatedMobile:string;
  mobileError: string |null;
  updateMobilePopup: boolean;
  otpToken: string;
  otp: number;
  full_phone_number: string;
  openOtpPopup:boolean;
  inValidOtp:boolean;
  personalDetailsSavedSuccess:boolean;
  phoneNumberUpdateError:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  updateEmailCallId:string ="";
  confirmEmailCallId:string="";
  updateMobileCallId:string="";
  confirmOtpCallId:string="";
  changeMobileCallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profile: {},
      profileImageUrl: "",
      fields: [],

      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      editPersonalDetails: false,
      editFirstName: "",
      editLastName:"",
      editEmail:"",
      editPhone: "",
      editDOB: "",
      editGender: null,
      logoutPopup:false,
      emailError:"",
      emailPopup:false,
      updatePopup: false,
      updatedEmail:"",
      successOpen:false,
      valueUpdated:"",
      updatedMobile:"",
      mobileError:"",
      updateMobilePopup:false,
      otpToken: "",
      otp: 0,
      full_phone_number: '',
      openOtpPopup:false,
      inValidOtp:false,
      personalDetailsSavedSuccess:false,
      phoneNumberUpdateError:""
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.checkParams()
    this.checkSessionStorage()
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };

  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
     if(apiRequestCallId === this.getCustomizableProfileCallId){
      this.setUserProfileData(responseJson)
     }
     if(apiRequestCallId === this.updateCustomizableProfileCallId){
      this.updateTheEditedData(responseJson)
     }
     if(apiRequestCallId === this.updateEmailCallId){
      this.checkErrors(responseJson)
     }
     if(apiRequestCallId === this.confirmEmailCallId){
      this.checkConfirmEmail(responseJson)
     }
     if(apiRequestCallId === this.updateMobileCallId){
      this.storeOtpResponse(responseJson)
     }
     if(apiRequestCallId === this.confirmOtpCallId){
        this.otpValidation(responseJson)
     }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  otpValidation=(responseJson:ResponseOnly)=>{
    if(!responseJson.errors){
      this.setState({openOtpPopup:false,successOpen: true, valueUpdated: 'Mobile Number'})
      this.changeMobile()
    }else{
      this.setState({inValidOtp: true})
    }
  }

  checkConfirmEmail=(responseJson:UserData)=>{
    if(responseJson.errors){
      if (responseJson.errors[0].token){
        this.showAlert('token','expired')
      }else{
        this.parseApiCatchErrorResponse(responseJson.errors)
      }
      
    }
    if(!responseJson.errors){
      this.setState({successOpen: true, valueUpdated: 'E-mail address'})
    }
  }

  storeOtpResponse = (responseJson: ResponseData) => {
    if (!responseJson.errors && !responseJson.message) {
      this.setState({
        full_phone_number: responseJson.data.attributes.full_phone_number, otp: responseJson.data.attributes.pin
        , otpToken: responseJson.meta.token, openOtpPopup: true, updateMobilePopup: false
      })
    }if(responseJson.message){
      this.setState({phoneNumberUpdateError:responseJson.message})
    }
    if (responseJson.errors) {
      if (responseJson.errors[0].token) {
        this.showAlert('token', 'expired')
      }else if(responseJson.errors[0].account){
        this.setState({phoneNumberUpdateError:"The number you entered is associated with another account."})
      }
       else {
        this.parseApiCatchErrorResponse(responseJson.errors)
      }
    }
  }
  checkParams=()=>{
    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get('token')
    const email = queryParams.get('email')
    if(token && email){
      localStorage.setItem('authToken',token)
      this.confirmEmail(token,email)
    }
    else{
      return false
    }
  }

  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  checkSessionStorage = () => {
    const token = localStorage.getItem('authToken')
    if (token) {
      this.token = token;
      this.getCustomizableProfile();
    }else{
      this.navigateToPages('EmailAccountLoginBlock')
    }
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  checkRequiredFields = () => {
    if (!this.state.saveEnabled) {
      for (const field of this.state.fields) {
        if (field.is_required && !this.state.profile[field.name]) return;
      }
      this.setState({ saveEnabled: true });
    } else {
      for (const field of this.state.fields) {
        if (field.is_required && this.state.profile[field.name] === undefined)
          return this.setState({ saveEnabled: false });
      }
    }
  };

  async componentDidUpdate() {
    if (this.state.currentProfile) {
      if (JSON.stringify(this.state.currentProfile) !== JSON.stringify(this.state.profile)) {
        if (!this.state.cancelEnabled) this.setState({ cancelEnabled: true });
        this.checkRequiredFields();
      }
    }
  }

  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  getCustomizableProfileFields = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileFieldsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileFieldsEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  resendEmail=()=>{
    this.updateEmailAddress(this.state.updatedEmail)
  }
  updateEmailAddress=(email:string)=>{
    this.setState({updatedEmail:email})
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateEmailCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateEmailAddressEndpoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body={
      "email": email
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  resendClick=()=>{
    this.updatePhoneNumber(this.state.updatedMobile)
  }
  changeErrorPhoneNumber=()=>{
    this.setState({phoneNumberUpdateError:""})
  }
  updatePhoneNumber=(Mobile:string)=>{
    this.setState({updatedMobile:Mobile})
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateMobileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.UpdateMobileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body={
      "data":{
        "attributes":{
            "full_phone_number": Mobile
        }
    }
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (item.is_required && !(item.value as string).trim() && item.is_enable) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    } else {
      this.updateProfile();
    }
  };

  cancelChanges = () => {
    if (this.state.currentProfile)
      this.setState({
        profile: { ...this.state.currentProfile },
        saveEnabled: false,
        cancelEnabled: false
      });
  };

  updateCustomizableProfile = () => {
    let gender;
      if (this.state.editGender === 'Male') {
        gender = 0;
      } else if (this.state.editGender === 'Female') {
        gender = 1;
      }else if (this.state.editGender === 'I prefer not to say'){
        gender = 3;
      }
       else {
        gender = 4;
      }
      
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCustomizableProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body={
      "gender": gender,
      "date_of_birth":this.state.editDOB,
      "first_name":this.state.editFirstName,
      "last_name": this.state.editLastName,
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

 confirmEmail = (token:string, email:string) => {
      
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.confirmEmailCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body={
      "email":email
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  confirmMobile = (otp:string) => {
    let number
      if(otp){
        number = parseInt(otp, 10)
      }
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token : localStorage.getItem('authToken')
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.confirmOtpCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.confirmOtpEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    let body

    if(otp){
      body={
        data: {
          otp_code: number,
          token: this.state.otpToken
            }
      }
    }else{
      body={
        data: {
          otp_code: this.state.otp,
          token: this.state.otpToken
            }
      }  
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  changeMobile = () => {
      
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.changeMobileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body={
      "full_phone_number":this.state.full_phone_number
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseURLGetProfileAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchAttributes = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getAttributesID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFields
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateProfile = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const deliveredData = this.state.attributes
      .map((item) => {
        if (item && item.is_enable) {
          return {
            [item.name]: item.value
          };
        }
      })
      .filter((item) => item != undefined);

    const filteredPosts: IProfileData = {};

    deliveredData.map((item) => {
      filteredPosts[Object.keys(item as object)[0]] = Object.values(item as object)[0];
    });

    const dataToBeUsed = {
      profile: filteredPosts
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateProfileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataToBeUsed)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleInputChange = (fieldName: keyof S, value: string | boolean | null): void => {
    const updatedState: Partial<S> = {
      [fieldName]: value,
    };
    this.setState(updatedState as S);
  };
  disableSubmitButton = (): boolean => {
    const { editFirstName, editLastName } =
      this.state;
    return (
      !editFirstName || !editLastName
    );
  };
  cancelForm = () => {
    this.setState({
      editFirstName: this.state.first_name,
      editLastName: this.state.lastName,
      editEmail: this.state.email,
      editPhone: this.state.phoneNumber,
      editDOB: this.state.dateOfBirth,
      editGender: this.state.gender,
      editPersonalDetails: false,
    })
  }
  submitForm = () => {
    // this.setState({
    //   first_name : this.state.editFirstName,
    //   lastName : this.state.editLastName,
    //   email : this.state.editEmail,
    //   phoneNumber : this.state.editPhone,
    //   editDOB: this.state.dateOfBirth,
    //   gender : this.state.editGender,
    //   editPersonalDetails: false,
    // })
    this.updateCustomizableProfile()

  }
  logOut=()=>{
    this.setState({logoutPopup:true})
  }
  navigateToPages=(navigator:string)=>{
    this.props.navigation?.navigate(navigator)
  }
  setUserProfileData=(responseJson:UserData)=>{
    if(!responseJson.errors){
      const { first_name, last_name, email, full_phone_number, gender, date_of_birth, country_code, phone_number } = responseJson;
      let phoneNumber
      if (phone_number){
        phoneNumber = `+${country_code} ${phone_number}`
      }else{
        phoneNumber = ""
      }
      let genderText;
      if (gender === 0 || gender === 'male') {
        genderText = 'Male';
      } else if (gender === 1 || gender === 'female') {
        genderText = 'Female';
      } else if (gender === 3 || gender === 'others') {
        genderText = 'I prefer not to say';
      } else{
        genderText = "";
      }
      this.setState({first_name, lastName: last_name, email, phoneNumber,gender:genderText, dateOfBirth: date_of_birth,
      editFirstName: first_name, editLastName:last_name, editEmail: email, editPhone:full_phone_number, editDOB: date_of_birth, editGender: genderText})
    }else if (responseJson.errors[0].token){
      this.showAlert('token','expired')
    }else{
      this.parseApiCatchErrorResponse(responseJson.errors)
    }
  }
  updateTheEditedData=(responseJson:UserData)=>{
    if(!responseJson.errors){
      this.setState({
          first_name : this.state.editFirstName,
          lastName : this.state.editLastName,
          dateOfBirth: this.state.editDOB,
          gender : this.state.editGender,
          editPersonalDetails: false,
        })
        this.setState({personalDetailsSavedSuccess:true})
        {
          const timer = setTimeout(() => {
            this.setState({personalDetailsSavedSuccess:false})
          }, 3000);
      
          return () => clearTimeout(timer);
        }
  }else{
  if (responseJson.errors[0].token){
    this.showAlert('token','expired')
  }else{
    this.parseApiCatchErrorResponse(responseJson.errors)
  }
}
}
  checkErrors = (responseJson: UserData) => {
    if (!responseJson.errors) {
      this.setState({ emailPopup: true, updatePopup: false })
    }
    if (responseJson.errors) {
      if (responseJson.errors[0].token) {
        this.showAlert('token', 'expired')
      } else {
        this.setState({ emailError: responseJson.errors })
      }
    }
  }
  removeError=()=>{
    this.setState({emailError:null})
  }
  closePopup=()=>{
    this.setState({updatePopup:false, updateMobilePopup: false})
  }
  emailClosePopup=()=>{
    this.setState({emailPopup:false})
  }
  closeSuccessPopup=()=>{
    this.setState({successOpen:false})
    window.location.reload();
  }
  handleCloseOtpPopup=()=>{
    this.setState({openOtpPopup:false})
  }
  navigateToPage = (navigator: MessageEnum) => {
    const message: Message = new Message(
      getName(navigator)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
