import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { SidesTypes, SpiceLevelTypes } from "../../ordermanagement/src/types";
import { CatalogueDataUnSorted, CatalogueDataUnSortedAttributes } from "./CfposordermanagementfeController";
import { arrayCheck, dealableProducts, getDynamicCustomization } from "../../../components/src/utils";
import { SpiceLevelType } from "../../catalogue/src/MenuPageController";

// Customizable Area Start

// CartDetails Interfaces
export interface CartImage {
  url: string;
  content_type: string;
  file_name: string;
}

export interface Side {
  catalogue_id: number;
  name: string;
  category_type: string;
  dealable_type: string;
  dealable_id: number;
}

export interface Topping {
  catalogue_id: number;
  name: string;
  price: number;
  category_type: string;
  dealable_type: string;
  dealable_id: number;
}

export interface CartItemAttributes {
  id: number;
  cart_id: number | null;
  catalogue_id: number;
  spice_level: string;
  choose_your_type: string;
  quantity: number;
  price: number;
  allergen_excluded: string | null;
  ready_for_delivery: boolean;
  product_name: string;
  sub_category: string;
  images: CartImage;
  sides: Side[];
  drinks: any[];
  nibbles: any[];
  toppings: Topping[];
  wraps_product: any[];
  allergen_in_item: AllergenInItem[],
  total_item_price:number
}

export interface CartItem {
  id: string;
  type: string;
  attributes: CartItemAttributes;
}

export interface CartDetails {
  data: CartItem[];
  total_price: string;
}

// OrderDetails Interfaces
export interface PersonalDetail {
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  order_id: number;
  created_at: string;
  updated_at: string;
}

export interface AccountAttributes {
  activated: boolean;
  country_code: string | null;
  email: string;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  phone_number: string | null;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
}

export interface Account {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

export interface OrderAttributes {
  id: number;
  order_number: string;
  restaurant: string;
  amount: number | null;
  account_id: number;
  promo_code_id: number | null;
  promo_code_name: string | null;
  coupon_code_id: number | null;
  redeem_point: number;
  points_worth: string;
  gift_card_ref_num: string | null;
  gift_card_amount: string;
  gift_card_id: number | null;
  delivery_address_id: number | null;
  sub_total: string;
  total: string;
  status: string;
  notes_to_chef: string | null;
  order_type: string;
  order_medium: string;
  pos_user_id: number;
  is_priority: boolean;
  prepare_immediately: boolean;
  schedule_time: string | null;
  custom_label: string | null;
  applied_discount: string;
  cancellation_reason: string | null;
  order_date: string | null;
  is_gift: boolean;
  placed_at: string | null;
  confirmed_at: string | null;
  cooking_at: string | null;
  ready_at: string | null;
  in_transit_at: string | null;
  delivered_at: string | null;
  cancelled_at: string | null;
  refunded_at: string | null;
  source: string | null;
  shipment_id: string | null;
  delivery_charges: number | null;
  tracking_url: string | null;
  payment_failed_at: string | null;
  payment_pending_at: string | null;
  returned_at: string | null;
  tax_charges: string;
  deliver_by: string | null;
  tracking_number: string | null;
  is_error: boolean;
  delivery_error_message: string | null;
  order_status_id: number;
  is_group: boolean;
  is_availability_checked: boolean;
  shipping_charge: number | null;
  shipping_discount: number | null;
  shipping_net_amt: number | null;
  shipping_total: number | null;
  total_tax: number | null;
  created_at: string;
  updated_at: string;
  delivery_addresses: any[];
  razorpay_order_id: string | null;
  charged: string | null;
  invoice_id: string | null;
  invoiced: string | null;
  order_items: any[];
  personal_detail: PersonalDetail;
  payment_transaction: any | null;
  account: Account;
}

export interface AllergenInItem{
  id: number;
  name: string;
  description:string;
}

export interface OrderData {
  id: string;
  type: string;
  attributes: OrderAttributes;
}

export interface OrderDetails {
  data: OrderData;
}

// Final API Response Interface
export interface ApiResponse {
  cart_details: CartDetails;
  order_details: OrderDetails;
}



export interface SubSubCategoryDeal {
  id: number;
  sub_category_id: number;
  title: string;
  description: string;
  created_at: string;
  updated_at: string;
  newly_launched: boolean;
  serves: string;
  spice_level: string;
  product_type: string;
  enable: boolean;
}

export interface SubCategoryDeal {
  id: number;
  food_category_id: number;
  title: string;
  created_at: string;
  updated_at: string;
  priority: number;
  enable: boolean;
}

export interface DealProduct {
  id: string;
  type: string;
  attributes: {
    id: number;
    dealable_type: string;
    dealable_id: number;
    quantity: number;
    dealable: SubSubCategoryDeal | SubCategoryDeal;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  locationSearch?:any
  spiceLevelList: SpiceLevelTypes[];
  catalogueItemData: CatalogueDataUnSorted | null;
  token:string;
  updateCartItemsOnAdding:(cartItems:CartDetails,orderDetails:OrderDetails)=>void
  // Customizable Area End
}

interface S {
  loading: boolean;
  // Customizable Area Start
  spiceLevel: string;
  chooseYourType: string;
  sidesDetails: SidesTypes[];
  selectedSides: number[];
  productChefNotes: string;
  productChefNotesWordCount: number;
  productChefNotesWordCountError: string;
  without_price_item: number[];
  with_price_array:{catalogue_id:number,price:number}[]
  [key: string]: any;
  itemInclude: string;
  eitherLightOrNibble: boolean;
  dealProduct: any;
  lightByteNibble: any;
  customProdutCategory: any;
  expanded: string | boolean;
  product_quantity: number;
  mandatoryFieldsButtonId:string[]
  mandatorySelectedIds: string[];
  chooseLigtherBites:string;
  lighterBiteOrNibbleSelected:number[];
  dietType: SpiceLevelType[];
  filterSelectedDietToppings:string;
  searchToppings:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfposPrductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  restaurant_id: string;
  getSidesDescriptionApiCallId: string;
  addItemsToCartApiCallId:string;
  [key: string]: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.restaurant_id = ""
    this.getSidesDescriptionApiCallId = ""
    this.addItemsToCartApiCallId=""
    this.changeChooseYourType = this.changeChooseYourType.bind(this);
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      loading: false,
      // Customizable Area Start
      spiceLevel: this.props.catalogueItemData ? this.props.catalogueItemData.attributes.sub_sub_category.spice_level : "",
      chooseYourType: "on its own",
      sidesDetails: [],
      selectedSides: [],
      productChefNotes: "",
      productChefNotesWordCount: 0,
      productChefNotesWordCountError: "",
      without_price_item: [],
      with_price_array:[],
      itemInclude: "",
      eitherLightOrNibble: false,
      dealProduct: [],
      lightByteNibble: [],
      customProdutCategory: [],
      expanded: false,
      product_quantity: 1,
      mandatoryFieldsButtonId:[],
      mandatorySelectedIds:[],
      chooseLigtherBites:"",
      lighterBiteOrNibbleSelected:[],
      dietType:[
        {
          "id": 1,
          "img": "",
          "title": 'All',
          "value":""
        },
        {
          "id": 2,
          "img": "",
          "title": 'Veg',
          "value": 'vegetarian'
        },
        {
          "id": 3,
          "img": "",
          "title": 'Non-Veg',
          "value":"non_vegetarian"
        },
      ],
      filterSelectedDietToppings:"",
      searchToppings:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (!apiRequestCallId && !responseJson) return;

      switch (apiRequestCallId) {
        case this.getSidesDescriptionApiCallId:
          this.setSidesDetails(responseJson);
          break;
        case this.addItemsToCartApiCallId:
          this.setItemsToCart(responseJson);
          break;
        default:
          if (responseJson.meta) {
            if (apiRequestCallId === this[`get${responseJson.meta.sub_category_id}DescriptionId`]) {
              this.updateStateWithResponse("Selected", "Details", responseJson);
            } else if (apiRequestCallId === this[`get${responseJson.meta.sub_category_id}CustomisationId`]) {
              this.updateStateWithResponse("ItemCustomisationSelected", "ItemCustomisation", responseJson);
            }
          }
          break;
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  componentDidMount = async () => {
    this.restaurant_id = localStorage.getItem("restaurantId") || ""
    if (this.props.catalogueItemData) {
      if (this.props.catalogueItemData.attributes.sides_details && this.props.catalogueItemData.attributes.sides_details.id) {
        this.getSidesDetails()
      }
      if (this.props.catalogueItemData.attributes.deal_products) {
        this.getCustomDealProductInfo(this.props.catalogueItemData)
      }
      if (this.props.catalogueItemData.attributes.item_customization) {
        this.getCustomisationItemsProductInfo(this.props.catalogueItemData)
      }
    }
  }

  updateStateWithResponse = (keySuffix: string, dataKey: string, responseJson: any) => {
    const stateAvail =  this.state[`set${responseJson.meta.sub_category_id}${keySuffix}`] || [];
    this.setState((prevState) => ({
      [`list${responseJson.meta.sub_category_id}${dataKey}`]: responseJson.data,
      [`set${responseJson.meta.sub_category_id}${keySuffix}`]: stateAvail, loading:false, mandatorySelectedIds: this.addMandatoryIds(prevState, responseJson.meta.sub_category_id, responseJson.data.length),
    }));
  };

  addMandatoryIds = (prevState: any, subCategoryId: string, dataLength: number) => {
    const isMandatory = prevState[`selectMandatory${subCategoryId}`];
    const isMultiple = prevState[`selectMultiple${subCategoryId}`];
  
    if (!isMandatory) return prevState.mandatorySelectedIds;
  
    const updatedIds = [...prevState.mandatorySelectedIds];
  
    if (isMultiple) {
      const currentCount = updatedIds.filter(id => id === `selectMandatory${subCategoryId}`).length;
      const remainingCount = dataLength - currentCount;
      if (remainingCount > 0) {
        updatedIds.push(...Array(remainingCount).fill(`selectMandatory${subCategoryId}`));
      }
    } else {
      if (!updatedIds.includes(`selectMandatory${subCategoryId}`)) {
        updatedIds.push(`selectMandatory${subCategoryId}`);
      }
    }
    return updatedIds;
  };


  getSplitItems = ["SPLIT", "NEXT HALF", "RESIZE"]

  setSpiceLevel = (event: React.ChangeEvent<HTMLInputElement>) => {
    const spiceLevel = event.target.value
    this.setState({ spiceLevel })
  }

  isSelectedSpiceLevel = (spiceLevelValue: string): boolean => {
    return spiceLevelValue === this.state.spiceLevel
  }

  changeChooseYourType = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ chooseYourType: event.target.value })
  }

  getSidesDetails = () => {
    this.setState({loading:true})
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSidesDescriptionApiCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSidesApiEndPoint}${this.restaurant_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  setSidesDetails = (responseJson: { data?: SidesTypes[] }) => {
    if (responseJson.data) {
      this.setState({ sidesDetails: responseJson.data,loading:false })
    }
  }

  handleSideChange = (sideId: number) => {
    this.setState((prevState) => {
      let selectedSides: number[] = prevState.selectedSides || [];

      selectedSides = selectedSides.includes(sideId) ?
        selectedSides.filter(id => id !== sideId) :
        [...selectedSides, sideId];

      if (selectedSides.length > 2) {
        return null;
      }

      return { selectedSides };
    });
  };

  handleChangeProductChefNotes = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const productChefNotes = event.target.value
    const productChefNotesWordCount = event.target.value.length
    if (productChefNotesWordCount > 50) {
      const productChefNotesWordCountError = "Cannot use more than 50 characters."
      this.setState({ productChefNotesWordCountError })
    } else {
      this.setState({ productChefNotesWordCountError: "" })
    }
    this.setState({ productChefNotes, productChefNotesWordCount })
  }

  setDynamicState = (value: string, id: number, idx: number, arrayToCheck?: any[]) => {
    if (this.state[`set${id}Selected`]) {
      let without_price_items_array = [...this.state.without_price_item]
      if (arrayToCheck && arrayCheck(arrayToCheck, value)) {
        let new_without_price_items_array = without_price_items_array.filter(item => !arrayToCheck.includes(item))
        new_without_price_items_array.push(Number(value))
        this.setState({ without_price_item: new_without_price_items_array })
      } else {
        without_price_items_array.push(Number(value))
        this.setState({ without_price_item: without_price_items_array })
      }
      const subCategory = [...this.state[`set${id}Selected`]];
      subCategory[idx] = Number(value);
      this.setState({ [`set${id}Selected`]: subCategory })
    }
  }

  setDynamicStateExtra = (value: string, price: number, id: number) => {
    this.setState((prevState) => {
      const selectedValue = Number(value);
      let with_price_items_array = [...prevState.with_price_array];
  
      const existingIndex = with_price_items_array.findIndex(item => item.catalogue_id === selectedValue);
  
      if (existingIndex !== -1) {
        with_price_items_array.splice(existingIndex, 1);
      } else {
        with_price_items_array.push({ catalogue_id: selectedValue, price });
      }
  
      return { with_price_array: with_price_items_array };
    });
  };

  setDynamicStateToppings = (value: string,id: number) => {
    this.setState((prevState) => {
      const selectedValue = Number(value);
      let without_price_items_array = [...prevState.without_price_item];
  
      // Check if the value exists in with_price_array
      const existingIndex = without_price_items_array.findIndex(item => item === selectedValue);
  
      if (existingIndex !== -1) {
        // If exists, remove it (toggle off)
        without_price_items_array.splice(existingIndex, 1);
      } else {
        // If not exists, add it
        without_price_items_array.push(selectedValue);
      }
  
      return { without_price_item: without_price_items_array };
    });
  };
  

  setItemsOnPriceBasis = (id: number, with_price: boolean, price: number, customizationId: number, mandatory: boolean) => {
    this.setState((prevState) => {

      let updatedWithPriceItems = [...prevState.with_price_array];
      let updatedWithoutPriceItems = [...prevState.without_price_item];
      let updatedMandatoryFieldsButtonId = [...prevState.mandatoryFieldsButtonId];

      const priceExists = updatedWithPriceItems.some((item) => item.catalogue_id === id);
      const priceItemExists = updatedWithoutPriceItems.includes(id);


      if (with_price) {
        if (priceExists) {
          updatedWithPriceItems = updatedWithPriceItems.filter((item) => item.catalogue_id !== id);
          updatedMandatoryFieldsButtonId = updatedMandatoryFieldsButtonId.filter((item, index) => {

            return item !== `selectMandatory${customizationId}` || index !== updatedMandatoryFieldsButtonId.indexOf(`selectMandatory${customizationId}`);
          });
        } else {
          updatedWithPriceItems = [...updatedWithPriceItems, { catalogue_id: id, price }];

          if (mandatory) {
            updatedMandatoryFieldsButtonId = [...updatedMandatoryFieldsButtonId, `selectMandatory${customizationId}`];
          }
        }
      } else {
        if (priceItemExists) {
          updatedWithoutPriceItems = updatedWithoutPriceItems.filter((item) => item !== id);
          updatedMandatoryFieldsButtonId = updatedMandatoryFieldsButtonId.filter((item, index) => {

            return item !== `selectMandatory${customizationId}` || index !== updatedMandatoryFieldsButtonId.indexOf(`selectMandatory${customizationId}`);
          });
        } else {
          updatedWithoutPriceItems = [...updatedWithoutPriceItems, id];
          if (mandatory) {
            updatedMandatoryFieldsButtonId = [...updatedMandatoryFieldsButtonId, `selectMandatory${customizationId}`];
          }
        }
      }

      return {
        with_price_array: updatedWithPriceItems,
        without_price_item: updatedWithoutPriceItems,
        mandatoryFieldsButtonId: updatedMandatoryFieldsButtonId,
      };
    });
  };


  
  setItemsOnPriceBasisSingle = (id: number, with_price: boolean, price: number, customizationId: number, mandatory: boolean,arrayTocheck:number[]) => {
    this.setState((prevState) => {
      const mandatoryId = mandatory ? `selectMandatory${customizationId}` : null;

      const updatedWithPriceItems = with_price
      ? [
          ...prevState.with_price_array.filter((item) => !arrayTocheck.includes(item.catalogue_id)),
          { catalogue_id: id, price }
        ]
      : prevState.with_price_array.filter((item) => !arrayTocheck.includes(item.catalogue_id));

    const updatedWithoutPriceItems = !with_price
      ? [
          ...prevState.without_price_item.filter((item) => !arrayTocheck.includes(item)),
          id
        ]
      : prevState.without_price_item.filter((item) => !arrayTocheck.includes(item));

      const updatedMandatoryFieldsButtonId = mandatoryId
        ? [...prevState.mandatoryFieldsButtonId, mandatoryId]
        : prevState.mandatoryFieldsButtonId;

      return {
        with_price_array: updatedWithPriceItems,
        without_price_item: updatedWithoutPriceItems,
        mandatoryFieldsButtonId: updatedMandatoryFieldsButtonId,
      };
    });
  };


  showVal = (id: number, idx: number) => {
    const selectedSet = this.state[`set${id}Selected`];
  if (selectedSet && selectedSet.length) {
    return String(selectedSet[idx]);
  }
    return ""
  }
  createArrayTocheckExtra = (dataArray: any[]) => {
    return dataArray.map(item => item.id)
  }

  customProductInformation = (id: number) => {
    if (id) {
      let api_url = `${configJSON.getCustomProductsApiEndPoint}${this.restaurant_id}&sub_category_id=${id}`

      const header = {
        "Content-Type": configJSON.validationApiContentType,

      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this[`get${id}DescriptionId`] = requestMessage.messageId

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        api_url
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      this.setState({loading:true})
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  getCustomDealProductInfo = (responseJson: CatalogueDataUnSorted) => {
    dealableProducts({ data: responseJson }, this.customProductInformation)
  }

  getCustomisationItemsProductInfo = (responseJson: CatalogueDataUnSorted) => {
    getDynamicCustomization(responseJson, this.itemCustomProductInformation)
  }

  containsIds = (deal_product_array: DealProduct[]) => {
    let showResult;
    showResult = deal_product_array?.filter(item => item.attributes.dealable_type === "BxBlockCatalogue::FoodCategory").length === 2 ? true : false
    return showResult
  }


  itemCustomProductInformation = (id: number,with_price:boolean,mandatory:boolean,multiple:boolean) => {
    if (id) {
      let api_url = `${configJSON.getCustomProductsApiEndPoint}${this.restaurant_id}&sub_category_id=${id}`
      this.setState({[`priceOption${id}`]:with_price,loading:true,[`selectMandatory${id}`]:mandatory,[`selectMultiple${id}`]:multiple})
      const header = {
        "Content-Type": configJSON.validationApiContentType,

      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this[`get${id}CustomisationId`] = requestMessage.messageId

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        api_url
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  getDealsTitle = (attributes: CatalogueDataUnSortedAttributes): string => {
    let title: string|undefined|null;
    if (attributes.deal_products
    ) {
      const subSubCategory = attributes.deal_products.find(
        (product) =>
          product.attributes &&
          product.attributes.dealable_type === "BxBlockCatalogue::SubSubCategory"
      );

      if (subSubCategory && subSubCategory.attributes && subSubCategory.attributes.dealable) {
        title = subSubCategory.attributes.dealable.title;
      }
    }
    return title||""
  }

  handleExpand = (panel: string) => (event: React.ChangeEvent<{}>, expanded: boolean) => {
    this.setState({ expanded: expanded ? panel : false });
  }

  getCheckedValue = () => [
    ...this.state.without_price_item,
    ...this.state.with_price_array.map(({ catalogue_id }) => catalogue_id),
  ];
  
  findCheckedStatus = (id:number) => this.getCheckedValue().some((value) => value == id);

  getCheckedValueToppings = () => this.state.with_price_array.map(({ catalogue_id }) => catalogue_id);

findCheckedStatusToppings = (id: number) => this.getCheckedValueToppings().includes(id);
findCheckedStatusToppingsFree = (id: number) => this.state.without_price_item.includes(id);

  buttonDisabled=()=>{
    const areAllMandatorySelected = this.state.mandatorySelectedIds.length <= this.state.mandatoryFieldsButtonId.length && this.state.mandatorySelectedIds.every(
      (item:string) => this.state.mandatoryFieldsButtonId.includes(item)
    );
    return !!this.state.productChefNotesWordCountError || !areAllMandatorySelected
  }

  minusTheProductQuantity=()=>{
    this.setState({product_quantity:this.state.product_quantity-1})
  }

  addTheProductQuantity=()=>{
    this.setState({product_quantity:this.state.product_quantity+1})
  }

  addItemsToCart = () => {
    const params = new URLSearchParams(this.props.locationSearch);
    const account_id = params.get('custId');
    const order_type = params.get('orderType');
    const { catalogueItemData, token } = this.props;
    if (!account_id || !order_type || !catalogueItemData) return;
    const transformToCatalogueId = (arr:number[]) => arr.map((id) => ({ catalogue_id: id }));
  
    const cartItem = {
      catalogue_id: catalogueItemData.id,
      spice_level: this.state.spiceLevel,
      choose_your_type: catalogueItemData.attributes.food_category === "Deals" ?"Combo Deal" :this.state.chooseYourType,
      quantity: this.state.product_quantity,
      price: this.state.chooseYourType === 'on its own' 
        ? catalogueItemData.attributes.on_its_own_price 
        : catalogueItemData.attributes.two_reg_sides_price,
      cart_item_toppings_attributes: this.state.with_price_array,
      cart_item_sides_attributes: [...transformToCatalogueId(this.state.without_price_item),...transformToCatalogueId(this.state.selectedSides), ...transformToCatalogueId(this.state.lighterBiteOrNibbleSelected)],
    };

    const body = {
      account_id: Number(account_id),
      order_type,
      cart: [cartItem],
    };
  
    const headers = {
      'Content-Type': configJSON.validationApiContentType,
      token,
    };
  

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addItemsToCartApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addItemsToCartApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    this.setState({loading:true})
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setItemsToCart=(responseJson:{cart_details?:CartDetails,order_details?:OrderDetails,error?:any})=>{
    if(responseJson.cart_details && responseJson.order_details){
      this.props.updateCartItemsOnAdding(responseJson.cart_details,responseJson.order_details)
      this.setState({loading:true})
    }
  }

  checkFinalPrice = (price: number) => {
    const extraPrice = this.state.with_price_array?.reduce((sum, item) => sum + item.price, 0) || 0;
    return (extraPrice + Number(price)).toFixed(2);
  };

  setNibblesLightBite = (value: string,idx:number) => {
    this.setState({[`chooseLigtherBites${idx}`]:value,lighterBiteOrNibbleSelected:[]});
  }

  setNibblesAndLightBite = (value: string, id: number, idx: number, arrayToCheck?: any[]) => {
    if (this.state[`set${id}Selected`]) {
      let lighterBiteOrNibbleSelected = [...this.state.lighterBiteOrNibbleSelected]
      if (arrayToCheck && arrayCheck(arrayToCheck, value)) {
        let new_lighterBiteOrNibbleSelected = lighterBiteOrNibbleSelected.filter(item => !arrayToCheck.includes(item))
        new_lighterBiteOrNibbleSelected.push(Number(value))
        this.setState({ without_price_item: new_lighterBiteOrNibbleSelected })
      } else {
        lighterBiteOrNibbleSelected.push(Number(value))
        this.setState({ lighterBiteOrNibbleSelected })
      }
      const subCategory = [...this.state[`set${id}Selected`]];
      subCategory[idx] = Number(value);
      this.setState({ [`set${id}Selected`]: subCategory })
    }
  }
  
  getToppingsDataSeparated=(deal_products:DealProduct[])=>{
    const toppings: DealProduct[] = [];
    const otherProducts: DealProduct[] = [];

    deal_products.length && deal_products.forEach((product) => {
      if (product.attributes.dealable.title === "Toppings") {
        toppings.push(product);
      } else {
        otherProducts.push(product);
      }
    });
    return {toppings,otherProducts}
  }
  handleDietChangeToppings=(event:React.ChangeEvent<HTMLInputElement>):void=>{
    this.setState({ filterSelectedDietToppings: event.target.value})
  }
  onChangeToppingsSearch=(event:React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({searchToppings:event.target.value})
  }
  handleSearchClear=()=>{
    this.setState({searchToppings:""})
  }

  filteredData = (catalogueData: any) => {
    const { searchToppings,filterSelectedDietToppings } = this.state;
  const searchTerm = searchToppings.toLowerCase();

  return catalogueData.filter((item: any) => {
    const categoryName = item.attributes.sub_sub_category_name.toLowerCase();
    const dietName = item.attributes.product_type.toLowerCase();

    // Filter based on search term and diet type (if diet type is not empty)
    const matchesSearch = categoryName.includes(searchTerm);
    const matchesDiet = filterSelectedDietToppings ? dietName ===filterSelectedDietToppings.toLowerCase() : true;

    return matchesSearch && matchesDiet;
  });
  };
  isAllItemsSelected = (createArray: number[]) => {

    const selectedCount = createArray.filter((item) =>
      this.state.without_price_item.includes(Number(item))
    ).length;

    return selectedCount >= 3;
  };
  // Customizable Area End
}
