import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import axios from "axios";

export const configJSON = require("./config");
export const configJSON1 = require("../../../framework/src/config.js");

export interface Props {
  navigation: any;
  id: string;
}

export interface FaqCategory {
  id: number;
  title: string;
  description: string;
}

export interface Data {
  id: number;
  title: string;
  content: string;
  category: FaqCategory;
  category_id: number;
}

export interface Category {
  id: string;
  attributes: Attribute;
}

export interface Attribute {
  id: number;
  title: string;
  description: string;
  image: Image;
}

export interface Image {
  url: string;
  image_name: string;
}

interface S {
  // Customizable Area Start
  count: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  subject: string;
  message: string;
  openPopup: boolean;
  openFaq: boolean;
  faqCategoryHeading: string;
  faqCategoryContent: string;
  faqData: Data[];
  accountHeading: string;
  accountContent: string;
  giftHeading: string;
  giftContent: string;
  restaurantHeading: string;
  restaurantContent: string;
  foodHeading: string;
  foodContent: string;
  sustainabilityHeading: string;
  sustainabilityContent: string;
  orderingHeading: string;
  orderingContent: string;
  searchQuery: string;
  category: Category[];
  expanded: string | boolean;
  countryCode: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  contactusApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
    ];

    this.state = {
      // Customizable Area Start
      count: 0,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      subject: "",
      message: "",
      openPopup: false,
      openFaq: false,
      faqCategoryHeading: "",
      faqCategoryContent: "",
      faqData: [],
      accountHeading: "My Account",
      accountContent:
        "Learn how to manage your account, update personal information, track orders",
      giftHeading: "Gift Cards & Discounts",
      giftContent:
        "Get to know everything about Wingo’s gift Cards , Discounts and Coupons",
      restaurantHeading: "Restaurants",
      restaurantContent:
        "Get to know everything about Wingo’s restaurants and franchise",
      foodHeading: "Food",
      foodContent:
        "Explore our menu offerings and find essential allergen information.",
      orderingHeading: "Online Ordering",
      orderingContent: "Learn about our collection and delivery options",
      sustainabilityHeading: "Sustainability",
      sustainabilityContent:
        "Sustainability practices and initiatives at Wingo restaurants.",
      searchQuery: "",
      category: [],
      expanded: "",
      countryCode: "44"
      // Customizable Area Start
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getCategories();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      let token = message.getData(getName(MessageEnum.SessionResponseToken));
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.contactusApiCallId) {
          // alert(JSON.stringify(responseJson.data.faqs))
          this.setState({ openPopup: true });
        }
      } else if (responseJson && responseJson.errors) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  handleWordCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const count = event.target.value.length;
    this.setState({ count: count });
  };

  handleInputChange = (fieldName: keyof S, value: string | boolean): void => {
    const updatedState: Partial<S> = {
      [fieldName]: value,
    };
    this.setState(updatedState as S);
  };

  handleClosePopup: () => void = () => {
    this.setState({ openPopup: false });
  };

  handleOpenPopup: () => void = () => {
    this.setState({ openPopup: true });
  };

  disableSubmitButton = (): boolean => {
    const { firstName, lastName, email, phoneNumber, message, subject } =
      this.state;
    return (
      !firstName || !lastName || !email || !phoneNumber || !message || !subject
    );
  };

  submitContact = async (form: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    subject: string;
    message: string;
  }): Promise<void> => {
    let formData = new FormData();
    formData.append("contact_us[first_name]", form.firstName);
    formData.append("contact_us[last_name]", form.lastName);
    formData.append("contact_us[email]", form.email);
    formData.append("contact_us[mobile_number]", form.phoneNumber);
    formData.append("contact_us[subject]", form.subject);
    formData.append("contact_us[message]", form.message);

    try {
      // Make POST request to /contactus endpoint with formData
      const response = await axios.post(
        `${configJSON1.baseURL}/${configJSON.contactusApiEndPoint}`,
        formData
      );
    } catch (error) {}
    this.handleOpenPopup();
  };

  openFaqCategory = async (
    categoryHeading: string,
    categoryContent: string,
    category_id: number
  ): Promise<void> => {
    try {
      this.setState({
        faqCategoryHeading: categoryHeading,
        faqCategoryContent: categoryContent,
      });
      const response = await axios.get(
        `${configJSON1.baseURL}/bx_block_interactive_faqs/interactive_faqs?category_id=${category_id}`
      );
      this.setState({ faqData: response.data.data, openFaq: true });
    } catch (error) {}
  };

  getSearchResults = async (
    categoryHeading: string,
    categoryContent: string,
    searchText: string
  ): Promise<void> => {
    try {
      if (this.state.searchQuery) {
        this.setState({
          faqCategoryHeading: categoryHeading,
          faqCategoryContent: categoryContent,
        });
        const response = await axios.get(
          `${configJSON1.baseURL}/${configJSON.searchApuEndPoint}query=${searchText}`
        );
        this.setState({ faqData: response.data.data, openFaq: true });
      }
    } catch (error) {}
  };

  onSearchChange = (searchText: string): void => {
    this.setState({ searchQuery: searchText });
  };

  getCategories = async () => {
    try {
      const response = await axios.get(
        `${configJSON1.baseURL}/bx_block_interactive_faqs/categories`
      );
      this.setState({ category: response.data.data });
    } catch (error) {}
  };

  handleExpand =
    (panel: string) => (event: React.ChangeEvent<{}>, expanded: boolean) => {
      this.setState({ expanded: expanded ? panel : false });
    };
}
// Customizable Area End
