import React from "react";
import { z } from "zod";
import { Event } from "react-big-calendar";
import moment from "moment";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton } from "@mui/material";
import { CloseRounded } from "@material-ui/icons";
import Modal from "../../../Modal";
import DateInput, { TimeInput } from "../../../DateTimePicker";
import CustomGreenCheckbox from "../../../CustomGreenCheckBox.web";
import { ContainedButton, OutlinedButton } from "../../../Button";
import ShiftSchema from "../../../Schemas/ShiftSchema";
import { getSessionStorage, parseDateTime } from "../../../utils";
import "./AddShift.css";

interface Props {
  update?: Event;
  apiError?: null | string[];
  addedShifts?: null | {
    date: string,
    message: string,
    shift: {
      start_time: string,
      end_time: string
    }
  }[] | null;
  selectedId?: number;
  open: boolean;
  onClose: () => void;
  submit: (data: any) => void;
};

const defaultErrors = {
  shift_date: "",
  till_date: "",
  start_time: "",
  end_time: "",
};

const AddShift = ({ update, apiError, addedShifts, selectedId, open, onClose, submit }: Props) => {
  const isUpdate = !!update;
  const settings = getSessionStorage("restaurant_settings");
  const isStoreManager = sessionStorage.getItem("role");
  const is24HourFormat = settings?.time_format === "24-hour";
  const [data, setData] = React.useState({
    id: selectedId,
    shift_date: "",
    till_date: "",
    start_time: "",
    end_time: "",
    one_week: false,
  });
  const [errors, setErrors] = React.useState<Record<string, string>>(defaultErrors);

  const handleDateChange = (date: Date | string | null, label: string) => {
    if (date) {
      if (typeof date === "string") {
        let value = date.replace(/\D/g, "");
        if (value.length > 4) value = value.slice(0, 4) + "-" + value.slice(4);
        if (value.length > 7) value = value.slice(0, 7) + "-" + value.slice(7, 9);

        if (label === "shift_date") {
          setData((prevData) => {
            const updatedData = { ...prevData, [label]: value };
            if (prevData.till_date && prevData.till_date < value) {
              updatedData.till_date = value;
            }
            return updatedData;
          });
        } else {
          setData({ ...data, [label]: value });
        }

        setErrors(defaultErrors);
        return value;
      }

      if (date instanceof Date) {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;

        if (label === "shift_date") {
          setData((prevData) => {
            const updatedData = { ...prevData, [label]: formattedDate };
            if (prevData.till_date && prevData.till_date < formattedDate) {
              updatedData.till_date = formattedDate;
            }
            return updatedData;
          });
        } else {
          setData({ ...data, [label]: formattedDate });
        }

        setErrors(defaultErrors);
        return formattedDate;
      }
    }

  };

  const handleTimeChange = (
    date: Date | string | null,
    label: string,
  ) => {
    if (date) {
      if (typeof date === "string") {
        let value = date.replace(/\D/g, "");
        if (value.length >= 4) {
          value = value.slice(0, 2) + ":" + value.slice(2, 4);
        }
        setData({ ...data, [label]: value });
        setErrors(defaultErrors);
        return value;
      }

      if (date instanceof Date) {
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");
        let formattedTime = "";

        if (!is24HourFormat) {
          const period = hours >= 12 ? "PM" : "AM";
          hours = hours % 12 || 12; // Convert to 12-hour format
          formattedTime = `${String(hours).padStart(2, "0")}:${minutes} ${period}`;
        } else {
          formattedTime = `${String(hours).padStart(2, "0")}:${minutes}`;
        }

        setData({ ...data, [label]: formattedTime });
        setErrors(defaultErrors);
        return formattedTime;
      }
    }

  };


  React.useEffect(() => {
    if (update) {
      setData({
        id: update.resource.staff_id,
        shift_date: moment(update.start).format("yyyy-MM-DD"),
        till_date: moment(update.end).format("yyyy-MM-DD"),
        start_time: moment(update.start).format(is24HourFormat ? "HH:mm" : "hh:mm A"),
        end_time: moment(update.end).format(is24HourFormat ? "HH:mm" : "hh:mm A"),
        one_week: false,
      });
    }
  }, [isUpdate]);

  const handleSubmit = () => {
    const body = {
      ...data,
      start_time: data.start_time ? moment(`${data.shift_date} ${data.start_time}`, "YYYY-MM-DD hh:mm A").format() : "",
      end_time: data.end_time ? moment(`${data.shift_date} ${data.end_time}`, "YYYY-MM-DD hh:mm A").format() : "",
    };

    const { success, error, data: validateData } = ShiftSchema.safeParse(body);
    const errorMap: Record<string, string> = {};

    if (!success) {
      if (error instanceof z.ZodError) {
        error.errors.forEach((err: any) => {
          errorMap[err.path[0]] = err.message;
        });

        setErrors(errorMap);
        return;
      }
    }

    submit(validateData);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <div className="add_shift__wrapper">
        <div className="add_shift__header">
          <span className="add_shift__header__title">{isUpdate ? "Update" : "Add"} Staff Shift Timing</span>

          <IconButton onClick={onClose}>
            <CloseRounded />
          </IconButton>
        </div>

        <div className="add_shift__response">
          {addedShifts && (
            <span className="add_shift__success">
              {addedShifts.length > 1 ? "Shifts" : "Shift"} Created for {addedShifts.map((shift) => `${shift.date}`).join(", ")}
            </span>
          )}

          {apiError && (
            <ul className="add_shift__error">
              {apiError.map((error: string, index: number) => {
                return (
                  <li key={index}>{error}</li>
                )
              })}
            </ul>
          )}
        </div>

        <div className="add_shift__form">
          <FormControl required>
            <FormLabel classes={{ asterisk: "add_shift__asterisk", root: "add_shift__label" }}>
              Date Applied
            </FormLabel>
            <DateInput
              disabled={isUpdate}
              minDate={!isStoreManager ? moment().tz(moment.tz.guess()).toDate() : null}
              onChange={(date) => handleDateChange(date, "shift_date")}
              onChangeRaw={(e) => handleDateChange(e.target.value, "shift_date")}
              placeholderText="YYYY-MM-DD"
              value={data.shift_date}
            />
            <FormHelperText
              classes={{ root: "add_shift__helper_text" }}
              error={!!errors["shift_date"]}
            >
              {errors["shift_date"]}
            </FormHelperText>
          </FormControl>

          {!isUpdate && (
            <FormControl>
              <FormLabel classes={{ asterisk: "add_shift__asterisk", root: "add_shift__label" }}>
                Till Date
              </FormLabel>
              <DateInput
                disabled={data.one_week}
                onChange={(date) => handleDateChange(date, "till_date")}
                onChangeRaw={(e) => handleDateChange(e.target.value, "till_date")}
                placeholderText="YYYY-MM-DD"
                value={data.till_date}
                minDate={moment(data.shift_date).tz(moment.tz.guess()).toDate()}
              />
              <FormHelperText
                classes={{ root: "add_shift__helper_text" }}
                error={!!errors["till_date"]}
              >
                {errors["till_date"]}
              </FormHelperText>
            </FormControl>
          )}

          <div className="add_shift__shift_timing">
            <span className="add_shift__shift_timing__title">Shift Timing</span>

            <div className="add_shift__shift_timing__grid">
              <FormControl required>
                <FormLabel classes={{ asterisk: "add_shift__asterisk", root: "add_shift__label" }}>
                  Start Time
                </FormLabel>
                <TimeInput
                  preventEdit
                  timeFormat={is24HourFormat ? "HH:mm" : "hh:mm aa"}
                  onChange={(date) => handleTimeChange(date, "start_time")}
                  placeholderText="HH:MM"
                  value={data.start_time}
                />
                <FormHelperText
                  classes={{ root: "add_shift__helper_text" }}
                  error={!!errors["start_time"]}
                >
                  {errors["start_time"]}
                </FormHelperText>
              </FormControl>

              <FormControl required>
                <FormLabel classes={{ asterisk: "add_shift__asterisk", root: "add_shift__label" }}>
                  End Time
                </FormLabel>
                <TimeInput
                  preventEdit
                  timeFormat={is24HourFormat ? "HH:mm" : "hh:mm aa"}
                  onChange={(date) => handleTimeChange(date, "end_time")}
                  placeholderText="HH:MM"
                  value={data.end_time}
                />
                <FormHelperText
                  classes={{ root: "add_shift__helper_text" }}
                  error={!!errors["end_time"]}
                >
                  {errors["end_time"]}
                </FormHelperText>
              </FormControl>

              {!isUpdate && (
                <FormControl>
                  <FormControlLabel
                    control={
                      <CustomGreenCheckbox
                        checked={data.one_week}
                        onChange={(e) => setData({ ...data, one_week: e.target.checked })}
                      />
                    }
                    label="Apply this shift to whole week"
                    classes={{ label: "add_shift__shift_timing__title" }}
                  />
                </FormControl>
              )}
            </div>
          </div>
        </div>

        <div className="add_shift__buttons">
          <OutlinedButton onClick={onClose}>
            Cancel
          </OutlinedButton>
          <ContainedButton onClick={handleSubmit}>
            Submit
          </ContainedButton>
        </div>
      </div>
    </Modal>
  );
};

export default AddShift;
