import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Event, View } from "react-big-calendar";
import { IconButton } from "@mui/material";
import { ClockInIcon, ClockOutIcon } from "../../../../../components/src/Icons";
import moment from "moment";

const ScheduleListColumns = () => {
    const columns: ColumnDef<Event, any>[] = [
        {
            header: "Day",
            accessorKey: "day",
        },
        {
            header: () => <div>Scheduled Time<br />(Log in)</div>,
            accessorKey: "start",
        },
        {
            header: () => <div>Scheduled Time<br />(Log off)</div>,
            accessorKey: "end",
        },
        {
            header: () => <div>User<br/ >Log in</div>,
            accessorKey: "login",
            cell: (info) => {
                if (info.row.original.resource?.is_on_leave) {
                    return <span style={{ color: "rgb(225, 27, 34)" }}>On Leave</span>
                } else return info.getValue();
            }
        },
        {
            header: () => <div>User<br/ >Log off</div>,
            accessorKey: "logout",
            cell: (info) => {
                if (info.row.original.resource?.is_on_leave) {
                    return <span style={{ color: "rgb(225, 27, 34)" }}>On Leave</span>
                } else return info.getValue();
            }
        },
        {
            header: "Action",
            accessorKey: "action",
            cell: (info) => {
                const { original } = info.row;
                const isToday = moment(moment(original.resource?.event?.start)).isSame(moment(), "day");
                const openLogin = original.resource?.openLogin;
                const isLoggedIn = original.resource?.login_details?.is_logged_in;
                const isLoggedOut = original.resource?.login_details?.is_logged_out;
                const isOnLeave = !!original.resource?.is_on_leave;
                const disableLoginButton = !isToday || isOnLeave || isLoggedIn;
                const disableLogoutButton = !isToday || isOnLeave || !isLoggedIn || isLoggedOut;

                return (
                    <div className="action_cell__wrapper">
                        <IconButton disabled={disableLoginButton} color="error" onClick={() => openLogin(original.resource?.event)}>
                            <ClockInIcon />
                        </IconButton>
                        <IconButton disabled={disableLogoutButton} color="success" onClick={() => openLogin(original.resource?.event)}>
                            <ClockOutIcon />
                        </IconButton>
                    </div>
                );
            },
        },
    ];

    return columns;
};

export default ScheduleListColumns;
