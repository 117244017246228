import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Input,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { allergenIcons, confirmOrder, messageCart, reviewOrder } from "./assets";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded"
import AddRoundedIcon from "@material-ui/icons/AddRounded"
import EditRoundedIcon from "@material-ui/icons/EditRounded"
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import { CartValueResponses, ShoppingCartItemsToOrder } from "./ShoppingCartOrdersController";
import EmptyCartItemAlert from "../../../components/src/EmptyCartItemAlert/EmptyCartItemAlert.web";
import DialogPopup from "../../../components/src/DialogPopup.web";
import ConfirmOrder from "../../../components/src/ConfirmOrder/ConfirmOrder.web";
import { showDips } from "../../../components/src/utils";
import "./ShoppingCartOrders.web.css"
import RecommendationEngine from "./../../recommendationengine/src/RecommendationEngine.web";
import SnackBarFav from "../../../components/src/SnackBarFav.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import ShoppingCartOrdersController, {
  Props,
} from "./ShoppingCartOrdersController";

export default class AddShoppingCartOrderItem extends ShoppingCartOrdersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  showSpiceLevel(item: any): boolean {
    if (item.attributes.spice_level && item.attributes.spice_level !== 'none') return true;
    return false;
  }

  reviewOrderContainer = (shoppingCartValueResponse:CartValueResponses) => {
    const items_to_shopping_cart = shoppingCartValueResponse.data
    return (
      <div className="wingos_cart_container">
        <div className="wingos_cart_heading">Wingo’s Cart</div>
        <div className="item_main_container_price">
          <div className="item_information_menu_option_container"><span>{items_to_shopping_cart.length} {items_to_shopping_cart.length<=1? "item":"items"} you have selected</span> <span style={{ color: "#E11B22", cursor:"pointer" }} onClick={this.navigateToMenuPage}>Explore Menu</span></div>
          <div></div>
        </div>
        <div className="item_main_container_price">
          <div className="item_description_cart_container">
            <div className="item_description_div">
              {items_to_shopping_cart.map((value: ShoppingCartItemsToOrder, index) =>
              {
              const { sides, drinks, nibbles, toppings } = value.attributes;
              const items = [
                ...sides.map((value) => value.name),
                ...drinks.map((value) => value.name),
                ...nibbles.map((value) => value.name),
                ...toppings.map((value) => value.name),
              ];
              const formattedItems = items.filter(item => item).join(', ') || "On its own";
              const showItems = " | " + formattedItems;
              return(
                <div key={index} className="item_details" style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                  <div style={{ display: "flex", flexDirection: "row", gap: "12px", alignItems: "center" }}>
                    <div style={{ display: "flex", flexDirection: "row", gap: "18px", alignItems: "center" }}>
                      <div style={{ borderRadius: "4px", height: "100px", width: "100px", backgroundImage: `url(${value.attributes.images.url})`, backgroundSize: "cover", backgroundPosition: "center" }}></div>
                      <div style={{ height: "100px", display: "flex", flexDirection: "column", gap: "4px" }}>
                        <div style={{ fontWeight: 700, fontSize: "18px", color: "#1D1D1D" }} className="item_name">{value.attributes.product_name}</div>

                        <div style={{ display: 'flex', flexDirection: 'column', fontSize: "14px", fontWeight: 500, color: "#747474" }}>
                          {this.showSpiceLevel(value) && (
                            <Typography style={{ fontFamily: "Barmeno" }} component="span" className="capitalize-text" >
                              {`${value.attributes.spice_level.replace('_', ' ')}`} {showItems}
                            </Typography>
                          )}
                          {showDips(value) && (
                            <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                              <Typography style={{ fontFamily: "Barmeno", color: "#1D1D1D" }} component="span">
                                Dips:
                              </Typography>
                              <Typography style={{ fontFamily: "Barmeno" }} component="span" className="capitalize-text">
                                {showDips(value)}
                              </Typography>
                            </div>
                          )}
                        </div>
                        {value.attributes.allergen_excluded&&
                        <div className="allergen_exclusion_info"> <img src={allergenIcons} alt="allergen_icons" style={{width:"16px", height:"16px"}} /> <span style={{display:"flex", height:"16px"}} > {value.attributes.allergen_excluded}</span></div>
                        }
                        <div style={{ color: "#000000", fontSize: "16px" }}>£{value.attributes.price}</div>
                      </div>
                    </div>
                    <Typography style={{fontFamily:"Barmeno"}} className="amount mr-lf-auto">£ {value.attributes.total_item_price}</Typography>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
                      <IconButton style={{ height: "32px", width: "32px" }} className='product_minus_add_button' onClick={() => this.addRemoveCartItems(value, 'removeItem')}><RemoveRoundedIcon style={{ color: "#286532" }} /></IconButton>
                      <div style={{ fontWeight: 700 }}>{value.attributes.quantity}</div>
                      <IconButton style={{ height: "32px", width: "32px" }} className='product_minus_add_button' onClick={() => this.addRemoveCartItems(value, 'addItem')}><AddRoundedIcon style={{ color: "#286532" }} /></IconButton>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: "24px" }}>
                      <IconButton onClick={() => this.editCartItem(value)} className='edit_button'><EditRoundedIcon style={{ color: "#2C6F37" }} /></IconButton>
                      <IconButton onClick={() => this.deleteSpecificItem(value.attributes.id)} className='edit_button'><DeleteOutlineRoundedIcon style={{ color: "#E11B22" }} /></IconButton>
                    </div>
                  </div>
                </div>)
              }
              )}
            </div>
            <div style={{display: 'flex', padding: '16px 0px',overflow:"auto", flexDirection:"column",gap:"16px"}}>
            <div style={{fontSize:"24px",fontWeight:700}} >You may also like</div>
            <RecommendationEngine navigation={this.props.navigation} id="you_may_like" restaurantId={this.state.restaurant_id} openItemDesc={this.navigateToMenuDescriptionAdd} cartDetailsData={this.props.cartdetails} addToFavourite={this.props.addToFavourite} removeFromFavourite={this.props.removeFromFavourite}  buttonText={'ADD TO CART'} setSnackBar={this.setSnackBar} />
          </div>
          </div>

          <div className="item_description_order_summary_container">
            <div className="item_description_div" style={{ padding: "24px" }} >
              <div style={{ fontSize: "24px", fontWeight: 500, color: "#1D1D1D" }}>Order Summary</div>
              <div className="subTotalSummary">
                <div className="subTotalEachItem">
                  <div className="subTotalKey">Sub Total</div> <div className="subTotalValue">£{shoppingCartValueResponse.order?shoppingCartValueResponse.order.sub_total : shoppingCartValueResponse.total_price}</div>
                </div>
                <div className="subTotalEachItem">
                  <div className="subTotalKey">Coupons / Discounts</div> <div style={{color: '#E11B22'}} className="subTotalValue">{this.shoppingCartOrdersValue(shoppingCartValueResponse,'coupons')}</div>
                </div>
                <div className="subTotalEachItem">
                  <div className="subTotalKey">Redeem Points</div> <div style={{color:'#E11B22'}} className="subTotalValue">{this.shoppingCartOrdersValue(shoppingCartValueResponse,'redeem_points')}</div>
                </div>
                <div className="subTotalEachItem">
                  <div className="subTotalKey">E-gift Card</div> <div style={{color: '#E11B22'}} className="subTotalValue">{this.shoppingCartOrdersValue(shoppingCartValueResponse,'gift_card')}</div>
                </div>
              </div>
              <div className="subTotalSummary">
              {shoppingCartValueResponse.save_value_message&&<div className="savings">*{shoppingCartValueResponse.save_value_message}</div>}
               {shoppingCartValueResponse.minimum_cart_value_message && <div className="minimumCartValue">{shoppingCartValueResponse.minimum_cart_value_message}</div>}
                <div className="subTotalEachItem" style={{ borderTop: "1px solid #BFBFBF", paddingTop: "12px" }}>
                  <div className="subTotalKey">Total</div> <div className="subTotalValue">£{shoppingCartValueResponse.order?shoppingCartValueResponse.order.total_price : shoppingCartValueResponse.total_price}</div>
                </div>
                <div className="shoppingCartMessage"><img src={messageCart} style={{height:"24px",width:"24px"}} />{shoppingCartValueResponse.message}</div>
              </div>
            </div>
            <textarea style={{fontFamily:'Barmeno', height:"100px", resize:"vertical",fontWeight:500, padding:"10px", border:"1px solid #BFBFBF", width:"100%"}} className="gift-amount-form-field" name="message" placeholder='Add notes to the chef...'
            onChange={(event)=>this.handleNotesToChef(event.target.value)} value={this.state.notes_to_chef} />
            <Button className={shoppingCartValueResponse.minimum_cart_value_message ?"ConfirmOrderButtonReviewOrderDisabled":"ConfirmOrderButtonReviewOrder"} disabled={!!shoppingCartValueResponse.minimum_cart_value_message } onClick={this.createOrder} >CONFIRM ORDER</Button>
          </div>



        </div>
        <SnackBarFav name="menu" isVisible={this.state.addeddSnackbar} handleClose={this.closeAddedSnackbar} action={this.state.snackbarAction} goToFavourites ={ this.goToFavourites} />
      </div>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <div className="review_cart_container">
          <div className="progess_bar_container">
            <div className="review_button_container_div">
              <IconButton style={{ padding: "0px" }} onClick={this.navigateToReviewOrder}><div className="review_order_iconbutton_div"><img src={reviewOrder} alt="reviewOrder" /></div></IconButton>
              <div style={{ position: "absolute", width: "max-content", top: "44px" }} >Review Order</div>
            </div >
            <div className="straight_green_line"></div>
            <div className="review_button_container_div">
              <IconButton><div className="review_order_iconbutton_div" style={{ backgroundColor: this.state.confirm_order? "":"#BFBFBF" }}><img src={confirmOrder} alt="confirmOrder" /></div></IconButton>
              <div id="confirmOrder" style={{ position: "absolute", width: "max-content", top: "44px" }}>Confirm Order</div>
            </div>
          </div>
          {this.state.confirm_order ? <ConfirmOrder restaun_fact={this.state.restaun_fact} orderDetails={this.state.order_detail} cart_id={this.state.shoppingCartValueResponse.data[0].attributes.cart_id} restaurant_id={this.state.restaurant_id} handleInputChangeFirstName={this.handleInputChangeFirstName} handleInputChangeLastName={this.handleInputChangeLastName} personalDetails={{firstName: this.state.edit_first_name,lastName: this.state.edit_last_name, fullPhoneNumber: this.state.edit_full_phone_number, order_id: this.state.order_id}}
          personalDetailsRead={{firstName: this.state.first_name,lastName: this.state.last_name, fullPhoneNumber: this.state.full_phone_number, order_id: this.state.order_id}} handleCountryCodeChange={this.handleCountryCodeChange} handleMobileChange={this.handleMobileChange} updateProfileOrder={this.updateProfileOrder} 
          editProfileOpenClose={this.editProfileOpenClose} editProfile = {this.state.editProfile} restaurantList={this.state.restaurantList} navigation={this.props.navigation} setOrderDetails={this.setOrderDetails} setGiftCardCouponPointsAmount ={this.setGiftCardCouponPointsAmount} save_value_message={this.state.save_value_message} showMinimumCartMessage={this.showMinimumCartMessage} getRestaurantDataToCatalogue={this.getRestaurantDataToCatalogue} deliveryInstructionList={this.state.deliveryInstructionList} deliveryInstruction={this.state.deliveryInstruction} changeDeliveryInstruction={this.changeDeliveryInstruction} setChangeDeliveryInstructions={this.setChangeDeliveryInstructions} isRestaurantChanged={this.isRestaurantChanged} /> : 
          this.reviewOrderContainer(this.state.shoppingCartValueResponse)}
        </div>
        <DialogPopup open={this.state.emptyCartPopup} onClose={this.closeEmptyCart}><EmptyCartItemAlert deleteCartItem={this.deleteCart} onClose={this.closeEmptyCart} /></DialogPopup>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
