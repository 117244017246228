import { z } from "zod";
import { Event } from "react-big-calendar";
import { CellContext } from "@tanstack/react-table";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import moment from "moment-timezone";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { StaffGeneralInfoSchema, PermitSchema, StaffAccountInfoSchema } from "../../../../../components/src/Schemas/AddNewStaff";
import { getResponseData, getSessionStorage, getCurrentRole } from "../../../../../components/src/utils";

export const configJSON = require("./config");

export type ReceivedFile = {
  url: string;
  content_type: string;
  file_name: string;
  size?: number;
};

export type Role = {
  id: number;
  name: string;
}

export interface StaffAttributes {
  id: number;
  staff_id: string;
  user_roles: Role[];
  full_name: string;
  first_name: string;
  last_name: string;
  country_code: string;
  phone_number: string;
  email: string;
  full_phone_number: string;
  identity_type: string;
  identity_document: ReceivedFile[];
  permit_require: boolean;
  visa: ReceivedFile[];
  expiry_date: string;
  post_code: string;
};

export type Staff = {
  id: string;
  type: string;
  attributes: StaffAttributes;
};

export interface Props {
    navigation: any;
}

export interface S {
  token: string;
  currentRole: string | null;
  role: string;
  selectedTab: number;
  data: StaffAttributes[];
  totalCount: number;
  totalPages: number;
  currentPage: number;
  nextPage: number | null;
  prevPage: number | null;
  openAddStaff: boolean;
  openUpdateStaff: boolean;
  staffId: string;
  availableIdentityTypes: { id: number; identity_type: string }[];
  availableRoles: { id: number; name: string }[];
  errors?: string[];
  validationErrors: Record<string, string>;
  roleFilter: number;
  search: string;
  selectedRow?: StaffAttributes;
  openConfirmation: boolean;
  selectedId: string | null;
  openManageNote: boolean;
  openNoteSuccess: boolean;
  staffCreated: boolean;
  events: Event[];
  staff: {
    "Chef": any[],
    "Online Order Manager": any[],
    "In Store Operator": any[],
    "Rider/Driver": any[],
    "In Store Manager": any[],
  };
  selectedIdForShift: number | null;
  selectedEvent: Event | null;
  openShiftPopup: boolean;
  updateError: null | string[];
  successfullyAddedShifts: null | {
    date: string,
    message: string,
    shift: {
      start_time: string,
      end_time: string
    }
  }[];
  selectedStaffForShift: null | string;
  selectedRole: null | string;
  totalShiftHours: string;
  openLogin: boolean;
  successPopup: {
    open: boolean;
    message?: string;
  };
  errorPopup: {
    open: boolean;
    message?: string | string[];
  },
  gaps: Record<string, string[]> | null;
  conflicts: Record<string, string[]> | null;
  weeklyNotes: {
    open: boolean;
    notes?: { id: number, note: string; create_at: string; }[]
    start?: string;
    end?: string;
    selectedDate?: Date;
  };
  openCalendar: HTMLElement | null;
  showAddNoteInput: boolean;
  note: string;
  noteError: string | null;
  timeOffErrors: string[];
  range: {
    start: string;
    end: string;
  };
}

export interface SS {}

export default class StaffInformationController extends BlockComponent<Props, S, SS> {
  staffInformationCallId: string = "";
  addStaffCallId: string = "";
  updateStaffCallId: string = "";
  identityOptionsCallId: string = "";
  getAvailableRolesCallId: string = "";
  addNoteCallId: string = "";
  staffByRoleCallId: string = "";
  staffShiftByIdCallId: string = "";
  addShiftByIdCallId: string = "";
  updateShiftByIdCallId: string = "";
  loginCallId: string = "";
  logoutCallId: string = "";
  getWeeklyNotesCallId: string = "";
  addWeeklyNotesCallId: string = "";
  timeOffCallId: string = "";
  deleteTimeoffCallId: string = "";
  deleteShiftCallId: string = "";
  deleteWeeklyNoteCallId: string = "";
  currentRoleParams: string = "";

  settings = getSessionStorage("restaurant_settings");

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      currentRole: null,
      role: "",
      selectedTab: 0,
      data: [],
      totalCount: 0,
      totalPages: 0,
      currentPage: 1,
      nextPage: null,
      prevPage: null,
      openAddStaff: false,
      openUpdateStaff: false,
      staffId: "",
      availableIdentityTypes: [],
      availableRoles: [],
      roleFilter: 0,
      search: "",
      validationErrors: {},
      openConfirmation: false,
      selectedId: null,
      openManageNote: false,
      openNoteSuccess: false,
      staffCreated: false,
      events: [],
      staff: {
        "Chef": [],
        "Online Order Manager": [],
        "In Store Operator": [],
        "Rider/Driver": [],
        "In Store Manager": [],
      },
      selectedIdForShift: null,
      selectedEvent: null,
      openShiftPopup: false,
      updateError: null,
      successfullyAddedShifts: null,
      selectedStaffForShift: null,
      selectedRole: null,
      totalShiftHours: "",
      openLogin: false,
      successPopup: {
        open: false,
        message: "",
      },
      errorPopup: {
        open: false,
        message: "Something went wrong. Please try again later.",
      },
      gaps: null,
      conflicts: null,
      weeklyNotes: {
        open: false,
        notes: [],
        start: "",
        end: "",
      },
      openCalendar: null,
      showAddNoteInput: false,
      note: "",
      noteError: null,
      timeOffErrors: [],
      range: {
        start: moment().startOf('month').format('YYYY-MM-DD'),
        end: moment().endOf('month').format('YYYY-MM-DD')
      }
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    await this.getToken();

    const currentRole = getCurrentRole();
    this.setState({ currentRole, role: currentRole ?? "" });

    this.getStaffInformation();
    this.getAvailableRoles();
    this.getStaffByRole();
  }

  componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<S>, _snapshot?: SS | undefined): void {
    if (prevState.currentRole !== this.state.currentRole) {
      this.currentRoleParams = this.state.currentRole ? `current_user_role=${this.state.currentRole}` : "";
    }
  }

  async getToken() {
    const token = localStorage.getItem("authToken");
    if (token) this.setState({ token });
  }

  getMessageHandler(callId: string, apiResponse: any) {
    const messageHandlers = {
      [this.staffInformationCallId]: () => this.handleStaffInformation(apiResponse),
      [this.addStaffCallId]: () => this.handleAddStaff(apiResponse),
      [this.updateStaffCallId]: () => this.handleUpdateStaff(apiResponse),
      [this.identityOptionsCallId]: () => this.handleAvailableIdentity(apiResponse),
      [this.getAvailableRolesCallId]: () => this.handleAvailableRoles(apiResponse),
      [this.addNoteCallId]: () => this.handleAddNote(apiResponse),
      [this.staffByRoleCallId]: () => this.handleStaffByRole(apiResponse),
      [this.staffShiftByIdCallId]: () => this.handleShiftById(apiResponse),
      [this.addShiftByIdCallId]: () => this.handleAddOrUpdateShiftById(apiResponse),
      [this.updateShiftByIdCallId]: () => this.handleAddOrUpdateShiftById(apiResponse),
      [this.loginCallId]: () => this.handleLogin(apiResponse),
      [this.logoutCallId]: () => this.handleLogout(apiResponse),
      [this.getWeeklyNotesCallId]: () => this.handleWeeklyNotes(apiResponse),
      [this.addWeeklyNotesCallId]: () => this.handleAddWeeklyNotes(apiResponse),
      [this.timeOffCallId]: () => this.handleTimeOff(apiResponse),
      [this.deleteTimeoffCallId]: () => this.handleDeleteAction(apiResponse),
      [this.deleteShiftCallId]: () => this.handleDeleteAction(apiResponse),
      [this.deleteWeeklyNoteCallId]: () => this.handleDeleteWeeklyNote(apiResponse),
    };

    return messageHandlers[callId];
  }

  setRange = (start?: string, end?: string) => {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

    this.setState({
      range: {
        start: start ?? startOfMonth,
        end: end ?? endOfMonth,
      }
    });
  };

  handleDeleteWeeklyNote = (response: any) => {
    if(response?.message) {
      this.setState({
        successPopup: {
          open: true,
          message: response.message
        }
      });
      this.getWeeklyNotes(this.state.weeklyNotes.start ?? "", this.state.weeklyNotes.end ?? "");
    }

    if(response?.error) {
      this.setState({
        errorPopup: {
          open: true,
          message: response.error
        }
      });
    }
  };

  handleDeleteAction = (response: any) => {
    if(response?.message) {
      this.setState({
        successPopup: {
          open: true,
          message: response.message
        }
      });
      this.getShift(this.state.selectedIdForShift ?? 0);
    }

    if(response?.error) {
      this.setState({
        errorPopup: {
          open: true,
          message: response.error
        }
      });
    }
  };

  handleTimeOff = (response: any) => {
    if (response?.errors) {
      this.setState({
        timeOffErrors: response.errors,
        errorPopup: {
          open: true,
          message: response.errors
        }
      });
    }

    if (response?.message) {
      this.getShift(this.state.selectedIdForShift ?? 0);
      this.setState({
        successPopup: {
          open: true,
          message: response.message
        },
        selectedIdForShift: null
      });
    }
  };

  handleWeeklyNotes = (response: any) => {
    if (response?.notes) {
      this.setState({
        weeklyNotes: {
          ...this.state.weeklyNotes,
          notes: response.notes
        }
      });
    }
  };

  handleAddWeeklyNotes = (response: any) => {
    if (response?.message) {
      this.handleAddNoteInputClose();
      this.setState({
        note: "",
        successPopup: {
          open: true,
          message: response.message
        },
      })
      this.getWeeklyNotes(this.state.weeklyNotes.start ?? "", this.state.weeklyNotes.end ?? "");
    }

    if (response?.error) {
      this.setState({
        errorPopup: {
          open: true,
          message: response.error
        }
      })
      this.handleAddNoteInputClose();
    }
  };

  handleLogin = (response: any) => {
    this.setState({ openLogin: false });

    if (response?.error) this.setState({
      errorPopup: { open: true, message: response.error }
    });

    if (response?.message) this.setState({
      successPopup: { open: true, message: response.message }
    }, () => this.getShift(this.state.selectedEvent?.resource?.staff_id));
  };

  handleLogout = (response: any) => {
    this.setState({ openLogin: false });

    if (response?.error) this.setState({
      errorPopup: { open: true, message: "No shift matches the provided logout time." }
    });

    if (response?.message) this.setState({
      successPopup: { open: true, message: response.message }
    }, () => this.getShift(this.state.selectedEvent?.resource?.staff_id));
  };

  handleAddOrUpdateShiftById = (response: any) => {
    if (this.handleSuccessfulShiftAddition(response)) return;
    if (this.hasError(response)) return;
    if (this.hasShiftErrors(response)) return;

    this.handleUnexpectedError();
  };

  hasError = (response: any) => {
    if (response?.error) {
      this.setState({ updateError: [response.error] });
      return true;
    }
    return false;
  };

  hasShiftErrors = (response: any) => {
    if (response?.errors) {
      const updateError = this.extractShiftErrors(response.errors);
      this.setState({ updateError });
      return true;
    }
    return false;
  };

  extractShiftErrors = (errors: any) => {
    const updateError: string[] = [];
    configJSON.shiftErrorKeys.forEach((key: string) => {
      if (errors[key]) {
        const keyErrors = errors[key]
          .map((error: any) => (error?.date ? `${error.date}: ` : "") + error?.message)
          .filter((message: string) => message.length > 0);
        updateError.push(...keyErrors);
      }
    });
    return updateError;
  };

  handleSuccessfulShiftAddition = (response: any) => {
    if (response?.successfully_added_shifts) {
      // Extract errors if they exist
      const updateError = this.extractShiftErrors(response);

      // Update state with successfully added shifts and any errors
      this.setState(
        {
          successfullyAddedShifts: response.successfully_added_shifts,
          updateError,
        },
        () => {
          // Close the modal only if there are no errors
          if (updateError.length === 0) {
            this.handleAddShiftClose();

          };

          // Fetch shift if a specific shift ID is selected
          if (this.state.selectedIdForShift) {
            this.getShift(this.state.selectedIdForShift);
          }
        }
      );
      return true;
    }
    return false;
  };

  handleUnexpectedError = () => {
    this.setState({
      updateError: ["Unexpected Error Occurred. Please try again!"],
    });
  };

  handleShiftById = (response: any) => {
    if (this.hasStaffSchedules(response)) return;

    if (this.hasShifts(response)) return;
  };

  hasStaffSchedules = (response: any) => {
    if (response?.staff_schedules?.length > 0) {
      this.setGapsAndConflicts(response);

      const events = this.createEventsFromStaffSchedules(response.staff_schedules);

      this.setState({ totalShiftHours: "", events });
      return true;
    }
    return false;
  };

  // Helper function to format gap messages
  formatGapMessages = (messages: string[], timeFormat: string): string[] => {
    return messages.map(message => {
      const matches = message.match(/between (\d+) and (\d+)/);
      if (matches) {
        const startTime = moment.unix(parseInt(matches[1], 10)).format(timeFormat);
        const endTime = moment.unix(parseInt(matches[2], 10)).format(timeFormat);
        return `Uncovered gap detected between ${startTime} and ${endTime}; schedule staff accordingly.`;
      }
      return message;
    });
  };

  // Helper function to format conflict messages
  formatConflictMessages = (messages: string[], timeFormat: string): string[] => {
    const datetimeRegex = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} [+-]\d{4}/g;
    return messages.map(text => {
      return text.replace(datetimeRegex, match => {
        return moment(match, "YYYY-MM-DD HH:mm:ss Z")
          .format(timeFormat);
      });
    });
  };

  setGapsAndConflicts = (response: any): void => {
    const timeFormat = this.settings?.time_format === "24-hour" ? "HH:mm" : "hh:mm A";
    const datetimeFormat = `YYYY-MM-DD ${timeFormat}`;

    if (response?.gaps) {
      const formattedGaps = Object.entries(response.gaps).reduce((acc, [date, messages]) => {
        const filteredMessages = (messages as string[]).filter((msg) => msg.trim() !== ""); // Skip empty messages
        if (filteredMessages.length > 0) {
          acc[date] = this.formatGapMessages(filteredMessages, timeFormat);
        }
        return acc;
      }, {} as Record<string, string[]>);

      this.setState({ gaps: Object.keys(formattedGaps).length > 0 ? formattedGaps : null });
    }

    if (response?.conflicts) {
      const formattedConflicts = Object.entries(response.conflicts).reduce((acc, [date, messages]) => {
        const filteredMessages = (messages as string[]).filter((msg) => msg.trim() !== ""); // Skip empty messages
        if (filteredMessages.length > 0) {
          acc[date] = this.formatConflictMessages(filteredMessages, datetimeFormat);
        }
        return acc;
      }, {} as Record<string, string[]>);

      this.setState({ conflicts: Object.keys(formattedConflicts).length > 0 ? formattedConflicts : null });
    }
  };

  createEventsFromStaffSchedules = (staffSchedules: any[]) => {
    return staffSchedules.flatMap((staff: any) =>
      staff.shifts.map((shift: any) => this.createEventFromShift(staff, shift))
    );
  };

  createEventFromShift = (staff: any, shift: any) => ({
    id: shift.id,
    title: staff.name,
    start: moment(shift.start_time).toDate(),
    end: moment(shift.end_time).toDate(),
    resource: this.createResource(staff, shift, null),
  });

  createEventsFromTimeOffs = (response: any): Event[] => {
    const open = this.settings?.store_hours?.[moment().format("dddd")]?.open;
    const close = this.settings?.store_hours?.[moment().format("dddd")]?.close;

    const timeOffEvents = response?.time_offs.map((timeOff: any) => {
      const startDate = moment(timeOff.start_date).format("YYYY-MM-DD");
      const endDate = moment(timeOff.end_date).format("YYYY-MM-DD");
      return {
        id: timeOff.id,
        title: `Time Off: ${timeOff.description}`,
        start: moment(`${startDate} ${open}`, "YYYY-MM-DD HH:mm").toDate(),
        end: moment(`${endDate} ${close}`, "YYYY-MM-DD HH:mm").toDate(),
        resource: this.createResource(response, null, timeOff),
      };
    });

    return timeOffEvents;
  };

  createResource = (staff: any, shift?: any, timeoff?: any) => {
    if (shift)
      return {
        id: `${JSON.stringify(staff.id)}-${JSON.stringify(shift.id)}`,
        staff_id: staff.id,
        shift_id: shift.id,
        type: "shift",
        name: staff.name,
        role: shift.shift_added_for,
        status: shift.staff_working_status,
        login_details: {
          login: this.convertToLocalDate(shift?.staff_log?.[0]?.login_time),
          is_logged_in: !!shift?.staff_log?.[0]?.login_time,
          logout: this.convertToLocalDate(shift?.staff_log?.[0]?.logout_time),
          is_logged_out: !!shift?.staff_log?.[0]?.logout_time,
        },
        timeoff_details: null,
        is_on_leave: false,
        openLogin: this.handleLoginModalOpen,
        deleteTimeoff: this.deleteTimeoff,
        deleteShift: this.deleteShift
      };

    if (timeoff)
      return {
        id: `${JSON.stringify(staff.id)}-${JSON.stringify(timeoff?.id)}`,
        staff_id: staff.id,
        type: "time_off",
        name: staff.name,
        role: "all",
        status: "on_leave",
        login_details: null,
        timeoff_details: {
          id: timeoff?.id,
          description: timeoff?.description,
          attachment: !!timeoff?.attachment ? timeoff?.attachment : null,
        },
        is_on_leave: true,
        openLogin: this.handleLoginModalOpen,
        deleteTimeoff: this.deleteTimeoff,
        deleteShift: this.deleteShift
      };
  };

  convertToLocalDate = (dateTime: string | null) => {
    return dateTime ? moment(dateTime).toDate() : null;
  };

  hasShifts = (response: any) => {
    if (response?.shifts) {
      let shifts: Event[] = [];
      let time_offs: Event[] = [];

      if (response?.time_offs) {
        time_offs = this.createEventsFromTimeOffs(response);
      }

      shifts = this.createEventsFromShifts(response);
      this.setState({ totalShiftHours: response?.total_shift_hours, events: [...shifts, ...time_offs] });
      return true;
    }

    return false;
  };

  createEventsFromShifts = (response: any): Event[] => {
    return response.shifts.map((shift: any) =>
      this.createEventFromShift(response, shift)
    );
  };

  handleStaffByRole = (apiResponse: any) => {
    if (apiResponse.staff_by_role) {
      let result: any = {};

      Object.keys(apiResponse.staff_by_role).forEach((role) => {
        if (!result[role]) {
          result[role] = [];
        }
        apiResponse.staff_by_role[role].forEach((staff: any) => {
          result[role].push({
            id: staff.attributes.id,
            staff_id: staff.attributes.staff_id,
            label: staff.attributes.full_name,
            role
          });
        });
      });

      this.setState({
        staff: result,
      });
    }
  };

  handleAddNote = (apiResponse: any) => {
    if (apiResponse.message) this.setState({ openNoteSuccess: true });

    this.handleManageNoteClose();
  };

  handleStaffInformation(data: any) {
    if (data.staff_members) {
      const staff: StaffAttributes[] = data.staff_members.map((staff: any) => {
        const attributes = staff.attributes;
        attributes.user_roles = this.processUserRoles(attributes.user_roles);
        attributes.identity_document = this.processIdentityDocument(attributes.identity_document);
        attributes.visa = this.processVisa(attributes.visa);

        return attributes;
      });

      this.setState({
        data: staff,
        totalCount: data.total_count,
        totalPages: Math.ceil(data.total_count / 10),
        currentPage: data.current_page,
        nextPage: data.next_page,
        prevPage: data.prev_page,
      });
    }
  }

  processUserRoles(user_roles: any): Role[] {
    if (user_roles) {
      return user_roles
        .map((role: any) => {
          const foundRole = this.state.availableRoles.find((availableRole) => availableRole.name === role);
          return foundRole ? { id: foundRole.id, name: foundRole.name } : null;
        })
        .filter(Boolean) as Role[];
    }
    return [];
  }

  processIdentityDocument(identity_document: any): ReceivedFile[] {
    return identity_document ? [identity_document] : [];
  }

  processVisa(visa: any): ReceivedFile[] {
    return visa ? [visa] : [];
  }

  handleAddStaff(data: any) {
    if (data.errors) {
      this.setState({ errors: data.errors });
      return;
    }

    this.handleAddStaffClose();
    this.getStaffInformation();
    this.handleStaffCreatedOpen();
  }

  handleUpdateStaff(data: any) {
    if (data.errors) {
      this.setState({ errors: data.errors });
      return;
    }

    this.handleUpdateStaffClose();
    this.getStaffInformation();
  }

  handleAvailableIdentity(data: any) {
    if (data.identity_types) {
      this.setState({ availableIdentityTypes: data.identity_types });
    }
  }

  handleAvailableRoles(data: any) {
    if (data.roles) {
      this.setState({ availableRoles: data.roles });
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const { response, callId, error } = getResponseData(message);

    if (!response) return;
    if (response?.error === "unauthorized") {
      this.props.navigation.navigate("PosNotAuthorized");
      return;
    }

    const handler = this.getMessageHandler(callId, response);
    if (handler) handler();
    if (error) runEngine.debugLog("API Error", error);
  }

  getStaffInformation(page: number = 1) {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.staffInformationCallId = getDataMsg.messageId;
    const filters = this.state.roleFilter ? `&role_ids=${this.state.roleFilter}` : "";
    const search = this.state.search ? `&search=${this.state.search}` : "";
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffInformationAPI.endPoint + `?page=${page}` + filters + search
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.staffInformationAPI.method);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  timeOff = (id: number, data: FormData) => {
    this.setState({ selectedIdForShift: id });
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.timeOffCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffScheduleAPI.endPoint + `/${id}` + configJSON.timeOffAPI + `?${this.currentRoleParams}`
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), data);
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  deleteTimeoff = (id: number, staffId: number) => {
    this.setState({ selectedIdForShift: staffId });
    const deleteDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteTimeoffCallId = deleteDataMsg.messageId;
    deleteDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffScheduleAPI.endPoint + `/${id}` + configJSON.deleteTimeoffAPI
    );
    deleteDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    deleteDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteMethod);
    runEngine.sendMessage(deleteDataMsg.id, deleteDataMsg);
  };

  deleteShift = (id: number, staffId: number) => {
    this.setState({ selectedIdForShift: staffId });
    const deleteDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteShiftCallId = deleteDataMsg.messageId;
    deleteDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffScheduleAPI.endPoint + `/${id}` + configJSON.staffShiftAPI.deleteShift
    );
    deleteDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    deleteDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteMethod);
    runEngine.sendMessage(deleteDataMsg.id, deleteDataMsg);
  };

  deleteWeeklyNote = (id: number) => {
    const deleteDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteWeeklyNoteCallId = deleteDataMsg.messageId;
    deleteDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffScheduleAPI.endPoint + `/${id}` + configJSON.weeklyNotesAPI.delete
    );
    deleteDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    deleteDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteMethod);
    runEngine.sendMessage(deleteDataMsg.id, deleteDataMsg);
  };

  getSchemas = (data: any, action: "add" | "update") => {
    return [
      { schema: StaffGeneralInfoSchema, tab: 0 },
      { schema: StaffAccountInfoSchema, tab: 1, condition: action === "add" },
      { schema: PermitSchema, tab: 0, condition: data.permitRequired },
    ];
  };

  validateSchema = (schema: any, data: any) => {
    const validation = schema.safeParse(data);
    const errorMap: Record<string, string> = {};

    if (!validation.success) {
      if (validation.error instanceof z.ZodError) {
        validation.error.errors.forEach((err: any) => {
          errorMap[err.path[0]] = err.message;
        });
        return { success: false, errorMap };
      }
    }
    return { success: true, errorMap };
  };

  validateData = (data: any, action: "add" | "update") => {
    const schemas = this.getSchemas(data, action);

    for (let { schema, tab, condition } of schemas) {
      if (condition === false) continue;
      const result = this.validateSchema(schema, data);
      if (!result.success) {
        this.setState({ validationErrors: result.errorMap });
        return { success: false, tab };
      }
    }

    return { success: true };
  };

  createFormData = (data: any, action: "add" | "update") => {
    const formData = new FormData();
    formData.append("account[staff_id]", action === "add" ? this.state.staffId : data.staffId);
    data.roles.forEach((role: number) => {
      formData.append("account[role_ids][]", role.toString());
    });
    if (data.permitRequired) {
      if (data.visaDocument.length > 0 && data.visaDocument[0] instanceof File)
        formData.append("account[visa]", data.visaDocument[0]);
      formData.append("account[expiry_date]", data.expiryDate);
    }
    formData.append("account[first_name]", data.firstName as string);
    formData.append("account[last_name]", data.lastName as string);
    formData.append("account[country_code]", data.countryCode as string);
    formData.append("account[phone_number]", data.phoneNumber.replace(data.countryCode, "") as string);
    formData.append("account[full_phone_number]", data.phoneNumber);
    formData.append("account[identity_type]", data.identityType as string);
    if (data.identityDocument.length > 0 && data.identityDocument[0] instanceof File)
      formData.append("account[identity_document]", data.identityDocument[0]);
    formData.append("account[permit_require]", JSON.stringify(data.permitRequired));
    formData.append("account[post_code]", data.postCode as string);
    formData.append("account[email]", data.email as string);
    formData.append("account[password]", data.password as string);

    return formData;
  };

  addStaff = (data: any) => {
    const validation = this.validateData(data, "add");
    if (!validation.success) {
      return validation;
    }

    const formData = this.createFormData(data, "add");

    const postDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addStaffCallId = postDataMsg.messageId;
    postDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addStaffAPI.endPoint);
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), {
      token: this.state.token,
    });
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.addStaffAPI.method);
    runEngine.sendMessage(postDataMsg.id, postDataMsg);

    return { success: true };
  };

  updateStaff = (data: any) => {
    const validation = this.validateData(data, "update");
    if (!validation.success) {
      return validation;
    }

    const formData = this.createFormData(data, "update");

    const postDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateStaffCallId = postDataMsg.messageId;
    postDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateStaffAPI.endPoint + `/${this.state.selectedRow?.id}`
    );
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), {
      token: this.state.token,
    });
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.updateStaffAPI.method);
    runEngine.sendMessage(postDataMsg.id, postDataMsg);

    return { success: true };
  };

  getAvailableIdentityTypes = () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.identityOptionsCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.availableIdentityTypesAPI.endPoint
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.availableIdentityTypesAPI.method);
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getAvailableRoles = () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAvailableRolesCallId = getDataMsg.messageId;
    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.availableRolesAPI.endPoint);
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.availableRolesAPI.method);
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getStaffByRole = () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.staffByRoleCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffScheduleAPI.endPoint
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getShift = (id: number, start?: string, end?: string) => {
    this.setState({ events: [] });
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.staffShiftByIdCallId = getDataMsg.messageId;
    const startDate = start ? `&start_date=${start}` : "";
    const endDate = end ? `&end_date=${end}` : "";
    if (id === 0) {
      getDataMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.staffScheduleForAllAPI.endPoint + `?${this.currentRoleParams}` + startDate + endDate
      )
    } else {
      getDataMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.staffScheduleAPI.endPoint + `/${id}` + configJSON.staffShiftAPI.getShift + `?${this.currentRoleParams}` + startDate + endDate
      );
    }
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getWeeklyNotes = (start: string, end: string) => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getWeeklyNotesCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffScheduleAPI.endPoint + configJSON.weeklyNotesAPI.get + `?start_date=${start}&end_date=${end}`
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  addWeeklyNotes = () => {
    const postDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addWeeklyNotesCallId = postDataMsg.messageId;
    postDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffScheduleAPI.endPoint + configJSON.weeklyNotesAPI.add + `?${this.currentRoleParams}`
    );
    postDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    const body = {
      date: moment(this.state.weeklyNotes.selectedDate).format("yyyy-MM-DD"),
      note: this.state.note
    };
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(postDataMsg.id, postDataMsg);
  };

  handleShiftAPI = (data: any) => {
    const shift = {
      shift_date: data.shift_date,
      start_time: data.start_time,
      end_time: data.end_time,
      role: this.state.selectedRole
    };

    const body = {
      shift: shift,
      ...(data.one_week
        ? { one_week: data.one_week.toString() }
        : { end_date: data.till_date }),
    };

    return body;
  };

  addShift = (data: any) => {
    this.setState({ successfullyAddedShifts: null, updateError: null });
    const postDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addShiftByIdCallId = postDataMsg.messageId;
    postDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffScheduleAPI.endPoint + `/${this.state.selectedIdForShift}` + configJSON.staffShiftAPI.addShift + `?${this.currentRoleParams}`
    );
    postDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    const body = this.handleShiftAPI(data);
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(postDataMsg.id, postDataMsg);
  };

  updateShift = (data: any) => {
    this.setState({ successfullyAddedShifts: null, updateError: null });
    const postDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateShiftByIdCallId = postDataMsg.messageId;
    postDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffScheduleAPI.endPoint + `/${this.state.selectedEvent?.resource?.staff_id}` + configJSON.staffShiftAPI.updateShift + `?${this.currentRoleParams}`
    );
    postDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    const body = this.handleShiftAPI(data);
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
    runEngine.sendMessage(postDataMsg.id, postDataMsg);
  };

  login = (data: { id: number; login_time: string }) => {
    const postDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.loginCallId = postDataMsg.messageId;
    postDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffScheduleAPI.endPoint + `/${data.id}` + configJSON.staffShiftAPI.login + `?${this.currentRoleParams}`
    );
    postDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    const body = { login_time: data.login_time };
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(postDataMsg.id, postDataMsg);
  };

  logout = (data: { id: number; logout_time: string }) => {
    const postDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.logoutCallId = postDataMsg.messageId;
    postDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffScheduleAPI.endPoint + `/${data.id}` + configJSON.staffShiftAPI.logout + `?${this.currentRoleParams}`
    );
    postDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    const body = { logout_time: data.logout_time };
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(postDataMsg.id, postDataMsg);
  };

  createMessage = (id: string, note: string): Message => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.staffInformationAPI.endPoint + `/${id}` + `/add_note` + `?${this.currentRoleParams}`
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({ note_text: note }));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    return getDataMsg;
  };

  sendMessage = (message: Message) => runEngine.sendMessage(message.id, message);

  addNote = (id: string, note: string) => {
    const getDataMsg = this.createMessage(id, note);
    this.addNoteCallId = getDataMsg.messageId;
    this.sendMessage(getDataMsg);
  };

  handleUpdateStaffOpen = () => {
    this.getAvailableIdentityTypes();
    this.setState({ openUpdateStaff: true });
  };

  handleUpdateStaffClose = () =>
    this.setState({
      openUpdateStaff: false,
      errors: undefined,
      validationErrors: {},
    });

  handleAddStaffOpen = () => {
    this.getAvailableIdentityTypes();
    this.setState({ openAddStaff: true });
  };

  handleAddStaffClose = () =>
    this.setState({
      openAddStaff: false,
      errors: undefined,
      validationErrors: {},
    });

  confirmClose = () => this.setState({ openConfirmation: true });

  handleConfirmationClose = () => this.setState({ openConfirmation: false });

  discardChanges = () => {
    this.handleUpdateStaffClose();
    this.handleConfirmationClose();
  };

  handleManageNoteOpen = (id: string) => this.setState({ selectedId: id, openManageNote: true });

  handleManageNoteClose = () => this.setState({ selectedId: null, openManageNote: false });

  handleNoteSuccessClose = () => this.setState({ openNoteSuccess: false });

  handleStaffCreatedOpen = () => this.setState({ staffCreated: true });

  handleStaffCreatedClose = () => this.setState({ staffCreated: false });

  handleTabChange = (_event: React.SyntheticEvent, value: any) => {
    this.setState({ selectedTab: value }, () => {
      if (value === 1) this.getShift(0, this.state.range.start, this.state.range.end);
      else this.getStaffInformation();
    });
  };

  handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) =>
    this.setState({ currentPage: page }, () => {
      this.getStaffInformation(page);
    });

  handleRoleFilterChange = (value: number) =>
    this.setState({ roleFilter: value }, () => {
      this.getStaffInformation(this.state.currentPage);
    });

  handleSelectedRow = (info: CellContext<StaffAttributes, any>) =>
    this.setState(
      {
        selectedRow: {
          ...info.row.original,
          identity_document: info.row.original.identity_document,
          visa: info.row.original.visa,
        },
      },
      () => {
        this.handleUpdateStaffOpen();
      }
    );

  handleViewDetails = (id: string) => this.props.navigation.navigate("ViewStaff", { id });

  handleSearchInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    this.setState({ search: e.target.value }, () => {
      this.getStaffInformation(this.state.currentPage);
    });

  updateEvents = (events: Event[]) =>
    this.setState({ events });

  handleAddShiftOpen = (id: number, selectedRole: string, event?: Event) => {
    this.setState({
      selectedIdForShift: id,
      selectedEvent: event ?? null,
      selectedRole,
      openShiftPopup: true
    });
  }

  handleAddShiftClose = () =>
    this.setState({ selectedIdForShift: null, selectedEvent: null, openShiftPopup: false, updateError: null, selectedRole: null, successfullyAddedShifts: null });

  handleLoginModalOpen = (event: Event) =>
    this.setState({ openLogin: true, selectedEvent: event });

  handleLoginModalClose = () =>
    this.setState({ openLogin: false, selectedEvent: null });

  handleSuccessPopupClose = () =>
    this.setState({
      successPopup: { open: false, message: "" }
    });

  handleErrorPopupClose = () =>
    this.setState({
      errorPopup: { open: false, message: "" }
    });

  handleWeeklyNotesOpen = (start: string, end: string) => {
    this.getWeeklyNotes(moment(start).format("yyyy-MM-DD"), moment(end).format("yyyy-MM-DD"));
    this.setState({
      weeklyNotes: {
        open: true,
        start,
        end,
        notes: this.state.weeklyNotes.notes,
      }
    });
  };

  handleWeeklyNotesClose = () => {
    this.setState({
      weeklyNotes: {
        open: false,
        notes: [],
        start: "",
        end: "",
        selectedDate: undefined
      },
      note: ""
    });
  };

  getNextWeek = (): void => {
    const { start, end } = this.state.weeklyNotes;
    const startOfNextWeek = moment(start).add(1, 'week').startOf('week');
    const endOfNextWeek = moment(end).add(1, 'week').endOf('week');
    this.setState({
      weeklyNotes: {
        open: true,
        start: startOfNextWeek.toISOString(),
        end: endOfNextWeek.toISOString(),
        notes: this.state.weeklyNotes.notes,
        selectedDate: startOfNextWeek.toDate()
      }
    }, () => this.getWeeklyNotes(startOfNextWeek.format("yyyy-MM-DD"), endOfNextWeek.format("yyyy-MM-DD")));
  };

  getPreviousWeek = (): void => {
    const { start, end } = this.state.weeklyNotes;
    const startOfPreviousWeek = moment(start).subtract(1, 'week').startOf('week');
    const endOfPreviousWeek = moment(end).subtract(1, 'week').endOf('week');
    this.setState({
      weeklyNotes: {
        open: true,
        start: startOfPreviousWeek.toISOString(),
        end: endOfPreviousWeek.toISOString(),
        notes: this.state.weeklyNotes.notes,
        selectedDate: startOfPreviousWeek.toDate()
      }
    }, () => this.getWeeklyNotes(startOfPreviousWeek.format("yyyy-MM-DD"), endOfPreviousWeek.format("yyyy-MM-DD")));
  };

  handleDateChange = (date: Date | null) => {
    if (date)
      this.setState({
        weeklyNotes: {
          open: true,
          start: this.state.weeklyNotes.start,
          end: this.state.weeklyNotes.end,
          notes: this.state.weeklyNotes.notes,
          selectedDate: date
        }
      });
  };

  handleCalendarOpen = (event: React.MouseEvent<HTMLElement>) =>
    this.setState({
      openCalendar: event.currentTarget
    });

  handleCalendarClose = () =>
    this.setState({
      openCalendar: null
    });

  handleAddNoteInputOpen = () =>
    this.setState({
      showAddNoteInput: true
    });

  handleAddNoteInputClose = () =>
    this.setState({
      showAddNoteInput: false,
      note: "",
    });

  handleNoteChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    this.setState({
      note: event.target.value,
      noteError: event.target.value.length > 100 ? "This field should not exceed 100 characters. Please adjust the input." : null
    });
}
