import { toast } from "react-toastify";
import { debounce } from "lodash";
import { AutocompleteInputChangeReason, SelectChangeEvent } from "@mui/material";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { closeConnection, connection } from "../../../../../components/src/Dashboard/WebSockets";
import { storeAdminConfig } from "../../../../../components/src/Dashboard/WebSockets/StoreAdmin";
import { checkPercentageStatus, getCurrentRole } from "../../../../../components/src/utils";
import { Data, OrderRevenue as OrderRevenueT, OrderStatistics as OrderStatisticsT } from "../types";
import moment from "moment";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
}

  export interface S {
    token: string;
    currentRole: string | null;
    role: string;
    filter: {
        date: "day" | "week" | "month" | "year" | "all_time"
    };
    search: string;
    data: Data;
    orderType: "collection" | "delivery";
    orderStatistics: OrderStatisticsT;
    orderRevenue: OrderRevenueT;
    actionMenuAnchorEl: HTMLElement | null;
    printDialog: boolean;
    receiptText: string;
    printerName: string;
    cupsServerIP: string;
    printReceiptResponse: string;
    orderActionResponse: string;
    searchData: any[];
    searchAnchorEl: HTMLElement | null;
    selectedRowId: string | null;
    weeklyNotes: { note: string; create_at: string }[];
}

export interface SS {}

export default class StoreAdminController extends BlockComponent<Props, S, SS> {
  orderStatisticsCallId: string = "";
  orderRevenueCallId: string = "";
  printReceiptApiCallId: string = "";
  orderActionCallId: string = "";
  searchCallId: string = "";
  getWeeklyNotesCallId: string = "";
  subscription: any | null = null;
  currentRoleParams: string = "";

  dateFilter = [
    {
      value: "day",
      label: new Date().toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
    },
    { value: "week", label: "Last 7 days" },
    { value: "month", label: "Last 30 days" },
    { value: "year", label: "Last 365 days" },
    { value: "all_time", label: "All time" },
  ];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleSearchInputChange = debounce(this.handleSearchInputChange.bind(this), 300);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      searchAnchorEl: null,
      token: "",
      currentRole: null,
      filter: { date: "day" },
      role: "",
      search: "",
      data: {
        active_orders: [],
        new_orders_count: 0,
        total_orders_count: 0,
        completed_orders_count: 0,
        percentage_change_in_total_orders: "N/A",
        percentage_change_in_completed_orders: "N/A",
      },
      orderType: "delivery",
      orderStatistics: {
        current_date: "",
        last_7_days_orders: 0,
        week_before_last_7_days_orders: 0,
        total_change: 0,
        percentage_change: 0,
        daily_completed_orders: [],
      },
      orderRevenue: {
        current_date: "",
        last_7_days_revenue: [],
        total_last_7_days_revenue: 0,
        total_previous_week_revenue: 0,
        percentage_change: 0,
      },
      actionMenuAnchorEl: null,
      printDialog: false,
      receiptText: "",
      printerName: "",
      cupsServerIP: "",
      printReceiptResponse: "",
      orderActionResponse: "",
      searchData: [],
      selectedRowId: null,
      weeklyNotes: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    await this.getToken();
    const currentRole = getCurrentRole();
    this.setState({ currentRole, role: currentRole ?? "" });
    this.getOrderStatistics();
    this.getOrderRevenue();
    this.getWeeklyNotes();
    this.subscribe();
  }

  async componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<S>, _snapshot?: SS | undefined): void {
    if (prevState.orderType !== this.state.orderType)
      this.getOrderRevenue();

    if (prevState.currentRole !== this.state.currentRole)
      this.currentRoleParams = this.state.currentRole ? `?current_user_role=${this.state.currentRole}` : "";
  }

  subscribe = () => {
    this.subscription = connection(this.state.token);

    storeAdminConfig({
      subscription: this.subscription,
      setDashboardData: (data: any) => this.setState({ data: data }),
      filter: this.state.filter,
    });
  };

  unsubscribe = () => {
    if (this.subscription) {
      console.log("Unsubscribing from web socket: ", this.subscription);
      closeConnection(this.subscription);
      this.subscription = null;
    };
  };

  getToken = async () => {
    const token = localStorage.getItem("authToken") || "";
    this.setState({ token });
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const callId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (errorMessage) runEngine.debugLog("API Error", errorMessage);
    if (!response) return;
    if (response?.error === "unauthorized") {
      this.props.navigation.navigate("PosNotAuthorized");
      return;
    }

    const messageHandlers = {
      [this.orderStatisticsCallId]: this.handleOrderStatistics,
      [this.orderRevenueCallId]: this.handleOrderRevenue,
      [this.orderActionCallId]: this.handleOrderAction,
      [this.printReceiptApiCallId]: this.handlePrintReceiveReceipt,
      [this.searchCallId]: this.handleSearch,
      [this.getWeeklyNotesCallId]: this.handleWeeklyNotes,
    };

    const handler = messageHandlers[callId];
    if (handler) handler(response);
  }

  handleWeeklyNotes = (response: any) => {
    const data = response;
    if (data?.notes) {
      this.setState({ weeklyNotes: data.notes });
    }
  };

  handleOrderStatistics = (response: any) => {
    const data = response;
    if (data) {
      const percentageChange =
        typeof data?.percentage_change === "number" ? data?.percentage_change.toString() + "%" : data?.percentage_change;
      data.daily_completed_orders = data?.daily_completed_orders?.map((order: any) => {
        const date = new Date(order.date);
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        order.day = days[date.getDay()];
        return order;
      });
      this.setState({
        orderStatistics: {
          ...data,
          percentage_change: percentageChange,
        },
      });
    }
  }

  handleOrderRevenue = (response: any) => {
    const data = response;
    if (data) {
      const percentageChange =
        !isNaN(Number(data.percentage_change)) ? Number(data.percentage_change).toString() + "%" : data.percentage_change;
      this.setState({
        orderRevenue: {
          ...data,
          percentage_change: percentageChange,
        },
      });
    }
  }

  handleOrderAction = (response: any) => {
    const data = response;
    if (data) {
      this.setState({ orderActionResponse: data.message });
      toast.success(data.message);
    }
  }

  handlePrintReceiveReceipt = (response: any) => {
    const data = response;
    if (data.status) {
      this.setState({
        printReceiptResponse: data.message,
      });

      setTimeout(() => {
        this.setState({
          printReceiptResponse: "",
        });
      }, 5000);
    }
  }

  handleSearch = (response: any) => {
    const data = response;

    if (data.message) {
      return;
    }

    const orders = data.orders.map((order: any) => ({
      type: "Order",
      id: order.id,
      label: `${order.order_number}`,
    }));
    const customers = data.customers.map((customer: any) => ({
      type: "Customer",
      id: customer.id,
      label: `${customer.full_name}`,
    }));
    const staff = data.staff.map((staff: any) => ({
      type: "Staff",
      id: staff.id,
      label: `${staff.full_name}`,
    }));
    const result = [...staff, ...orders, ...customers];

    this.setState({ searchData: result });
  }

  handlePrint = () => {
    const printData = {
      receipt: this.state.receiptText,
      printer_name: this.state.printerName,
      cups_server: this.state.cupsServerIP,
    };

    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.printReceiptApiCallId = getDataMsg.messageId;
    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.printReceiptAPI.endPoint);
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.printReceiptAPI.contentType,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(printData));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.printReceiptAPI.method);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  generateOrderActionUrl = (orderId: string, action: string, filterDate: string, configJSON: any) => {
    const endpoint = configJSON.orderActionAPI.endPoint;
    const actionPath = configJSON.orderAction[action];
    const dateRange = filterDate || "day";

    return `${endpoint}/${orderId}/${actionPath}?date_range=${dateRange}`;
  }

  orderAction = (orderId: string, action: "accept" | "cancel" | "skip") => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.orderActionCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.generateOrderActionUrl(orderId, action, this.state.filter.date, configJSON) + this.currentRoleParams
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    if (action === "skip") {
      getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    } else {
      getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    }
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getOrderStatistics = () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.orderStatisticsCallId = getDataMsg.messageId;

    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.orderStatistics.endPoint);

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );

    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.orderStatistics.method);

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  searchQuery = async (search: string) => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.searchCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.search.endPoint + "?search_data=" + search + "&token=" + this.state.token
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );

    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.search.method);

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getOrderRevenue = () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.orderRevenueCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.orderRevenue.endPoint + "?order_type=" + this.state.orderType
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );

    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.orderRevenue.method);

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getWeeklyNotes = () => {
    const start = moment().startOf("week").format("YYYY-MM-DD");
    const end = moment().endOf("week").format("YYYY-MM-DD");
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getWeeklyNotesCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWeeklyNotesAPI.endPoint + `?start_date=${start}&end_date=${end}`
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };


  handleSearchInputChange = (_event: React.SyntheticEvent, value: string, _reason: AutocompleteInputChangeReason) => {
    this.setState({ search: value });

    if (value) {
      this.searchQuery(value);
    }
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [e.target.name]: e.target.value,
    } as unknown as Pick<this["state"], keyof this["state"]>);
  };

  handleCardFooter = (percentage: number | string) => {
    if (typeof percentage === "string") return "Not enough data";

    const status = checkPercentageStatus(percentage);
    const percentageString = percentage % 1 !== 0 ? percentage.toFixed(2) : percentage;

    if (status === "positive") return "+" + percentageString + "%" + " than usual";
    else return percentageString + "%" + " than usual";
  };

  handleSearchInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ search: e.target.value });
  };

  handleSearchClick = (option: any) => {
    switch (option.type) {
      case "Staff":
        try {
          this.props.navigation.navigate("ViewStaff", { id: option.id });
        } catch (error) {
          return false;
        }
        break;
      case "Customer":
        try {
          this.props.navigation.navigate("Customers", { id: option.id });
        } catch (error) {
          return false;
        }
        break;
      case "Order":
        try {
          this.props.navigation.navigate("Orders", { id: option.id });
        } catch (error) {
          return false;
        }
        break;
      default:
        try {
          this.props.navigation.navigate("");
        } catch (error) {
          return false;
        }
        break;
    }
  };

  handleDateChange = (event: SelectChangeEvent) => {
    this.unsubscribe();
    this.setState(
      {
        filter: {
          date: event.target.value as "year" | "month" | "day" | "week" | "all_time",
        },
      },
      () => this.subscribe()
    );
  };

  handleActionMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    this.setState({
      selectedRowId: id,
      actionMenuAnchorEl: event.currentTarget,
    });
  };

  closeActionMenu = () => {
    this.setState({
      actionMenuAnchorEl: null,
      selectedRowId: null,
    });
  };

  openPrintDialog = () => {
    this.setState({ printDialog: true });
  };

  closePrintDialog = () => {
    this.setState({ printDialog: false });
  };

  handlePrintReceipt = () => {
    this.openPrintDialog();
    this.closeActionMenu();
  };

  handleAcceptOrder = (id: string) => {
    this.orderAction(id, "accept");
    this.closeActionMenu();
  };

  handleCancelOrder = (id: string) => {
    this.orderAction(id, "cancel");
    this.closeActionMenu();
  };

  handleSkipStatus = (id: string) => {
    this.orderAction(id, "skip");
    this.closeActionMenu();
  };
}
