import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { CatalogueDataUnSorted } from "./../../cfposordermanagementfe/src/CfposordermanagementfeController";
import { Cart } from "../../catalogue/src/MenuPageController";
import {itemsListFav } from "../../../components/src/utils";
import { addToFavourite, removeFromFavourite } from "../../../components/src/apiCall";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  restaurantId?:string |null;
  openItemDesc:(id:string)=>void
  cartDetailsData:Cart
  addToFavourite:(id:number)=>void
  removeFromFavourite:(id:number)=>void
  buttonText:string;
  setSnackBar:(val:boolean,val2:string)=>void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  productList: CatalogueDataUnSorted[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RecommendationEngineController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDefaultSettingProductCallId: string = "";
  getCustomSettingProductCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      productList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId === this.getCustomSettingProductCallId) {
      this.setRecommendedList(responseJson)
    }
    else if (errorReponse || responseJson.errors) {
      this.parseApiErrorResponse(responseJson);
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    if(this.props.restaurantId){
      this.getCustomSettingProducts()
      }
  }
  componentDidUpdate = async (prevProps:Props) => {
    if(prevProps.restaurantId !== this.props.restaurantId || prevProps.cartDetailsData !== this.props.cartDetailsData){
    this.getCustomSettingProducts()
    }
  }
  setRecommendedList = (responseJson: { data?: CatalogueDataUnSorted[] }) => {
    if (responseJson.data) {
      this.setState({ productList: responseJson.data })
    }
  }

  getCustomSettingProducts = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": localStorage.getItem('authToken') ||""
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCustomSettingProductCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customSettingEndPoint}${this.props.restaurantId}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  favUnFav = (id: number,fav: boolean) => {
    const data = this.state.productList
    const response = itemsListFav(data, id, fav);
   this.setState({ productList: response })
  }

  addToFavourite =  async (id: number)=>{

    const error = await addToFavourite(id)
    if(error===null){
     this.favUnFav(id, true);
     this.props.setSnackBar(true,"added");
     if(this.props.addToFavourite){
      this.props.addToFavourite(id)
     }
    }else if(error === 401){
     this.showAlert('token',"expired")
    }
   }
   
   removeFromFavourite =  async (id: number)=>{
     const error = await removeFromFavourite(id)
     if(error!==null){
       const msg: Message = new Message(
         getName(MessageEnum.NavigationEmailLogInMessage)
       );
       msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
       msg.addData(getName(MessageEnum.NavigationEmailLogInMessage,), "email");
       this.send(msg);
      this.showAlert("Error", error, "Sign In", msg)
      }else{
       this.favUnFav(id, false);
       this.props.setSnackBar(true,"remove");
       if(this.props.removeFromFavourite){
        this.props.removeFromFavourite(id)
       }
   }
   }
  // Customizable Area End
}
